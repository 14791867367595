// utils/storage.ts
export const saveState = <T>(key: string, state: T): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.error("Error saving state to storage", err);
  }
};

export const  loadState =<T>(key: string): T | null =>{
  try {
      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
          return null;
      }
      return JSON.parse(serializedState) as T;
  } catch (err) {
      console.error("Failed to load state:", err);
      return null;
  }
}
