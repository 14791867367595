// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Of11 from "../../assets/images/of11.png";
// import Of12 from "../../assets/images/of12.png";
// import Of21 from "../../assets/images/of21.png";
// import Of22 from "../../assets/images/of22.png";
// import Of31 from "../../assets/images/of31.png";
// import Of32 from "../../assets/images/Of322.png";
// import Of41 from "../../assets/images/of41.png";
// import Of42 from "../../assets/images/of42.png";
// import Of51 from "../../assets/images/of51.png";
// import Of52 from "../../assets/images/of52.png";
// import Of61 from "../../assets/images/of61.png";
// import Of62 from "../../assets/images/of62.png";
// import Of71 from "../../assets/images/of71.png";
// import Of72 from "../../assets/images/of72.png";
// import Of81 from "../../assets/images/of81.png";
// import Of82 from "../../assets/images/of82.png";
// import Of91 from "../../assets/images/of91.png";
// import Of92 from "../../assets/images/of92.png";
// import Of101 from "../../assets/images/of101.png";
// import Of102 from "../../assets/images/of102.png";
// import Of111 from "../../assets/images/of111.png";
// import Of112 from "../../assets/images/of112.png";

// const PauseOnHover: React.FC = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     pauseOnHover: true,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const storedCode = localStorage.getItem("countryCode");
//   const offerCards = [

//     {
//       img1: Of12,
//       img2: Of11,
//       buttonText: "Order Now",
//       textBelow: "Your favorite energy boost is back",
//       bgColor: "#C30F16",
//       btnColor: "#FDD107",
//       countryCode: "IN",
//     },
//     {
//       img1: Of22,
//       img2: Of21,
//       buttonText: "Cook Exotic Dishes",
//       textBelow: "Wholesome grains, perfect for every meal",
//       bgColor: "#115414",
//       btnColor: "#63B76D",
//       countryCode: "IN",
//     },
//     {
//       img1: Of32,
//       img2: Of31,
//       buttonText: "Order Now",
//       textBelow: "Indulge in silky, chocolaty bliss",
//       bgColor: "#7E358F",
//       btnColor: "#368BAF",
//       countryCode: "IN",
//     },
//     {
//       img1: Of42,
//       img2: Of41,
//       buttonText: "Order Now",
//       textBelow: "Indulge in silky, chocolaty bliss",
//       bgColor: "#AD7043",
//       btnColor: "#1D1B1C",
//       countryCode: "IN",
//     },
//     {
//       img1: Of52,
//       img2: Of51,
//       buttonText: "Order Now",
//       textBelow: "Your favorite energy boost is back",
//       bgColor: "#FFFFFF",
//       btnColor: "#D81C1B",
//       countryCode: "IN",
//     },

//     //UK
//     {
//       img1: Of62,
//       img2: Of61,
//       buttonText: "Order Now",
//       textBelow: "Crunch into happiness, one chip at a time!",
//       bgColor: "#D5AD5E",
//       btnColor: "#F07532",
//       countryCode: "UK",
//     },
//     {
//       img1: Of72,
//       img2: Of71,
//       buttonText: "Order Now",
//       textBelow: "Pop, crunch, and enjoy the fun",
//       bgColor: "#FFFFFF",
//       btnColor: "#C00B1A",
//       countryCode: "UK",
//     },
//     {
//       img1: Of82,
//       img2: Of81,
//       buttonText: "Order Now",
//       textBelow: "Delight in every buttery bite",
//       bgColor: "#330C0C",
//       btnColor: "#E3262A",
//       countryCode: "UK",
//     },
//     {
//       img1: Of92,
//       img2: Of91,
//       buttonText: "Order Now",
//       textBelow: "Crunchy, nutty, and irresistibly tasty",
//       bgColor: "#25196B",
//       btnColor: "#80CFFF",
//       countryCode: "UK",
//     },
//     {
//       img1: Of102,
//       img2: Of101,
//       buttonText: "Order Now",
//       textBelow: "Indulge in silky, chocolaty bliss",
//       bgColor: "#481F7D",
//       btnColor: "#E4C170",
//       countryCode: "UK",
//     },
//     {
//       img1: Of112,
//       img2: Of111,
//       buttonText: "Order Now",
//       textBelow: "Good food, good life, every time",
//       bgColor: "#FFB295",
//       btnColor: "#63513D",
//       countryCode: "UK",
//     },
//   ];

//   return (
//     <div className="w-full mt-4">
//      <Slider {...settings}>
//         {offerCards
//           .filter((card) => card.countryCode === storedCode)
//           .map((card, index) => (
//             <div
//               key={index}
//               className={`rounded-2xl flex gap-4 w-full h-52 bg-[${card.bgColor}] ${
//                 card.bgColor === "#FFFFFF" ? "border border-gray-300 border-2 rounded-2xl" : ""
//               }`}
//               style={{ marginRight: "20px" }} // Add space between cards
//             >
//               <div className="flex flex-row justify-between p-4">
//                 <div className="space-y-4">
//                   <img
//                     src={card.img1}
//                     alt={`Offer Card ${index + 1}`}
//                     className="w-20 h-auto object-cover justify-start"
//                   />
//                   <div
//                     className={`rounded-lg p-2 w-3/4 flex items-center justify-center tracking-widest text-sm font-sans justify-center bg-[${
//                       card.btnColor
//                     }] ${
//                       card.btnColor === "#FDD107" && "#F07532"
//                         ? "text-[#313131]"
//                         : "text-white"
//                     }`}
//                   >
//                     {card.buttonText}
//                   </div>
//                   <div className="justify-items-end">
//                     <span
//                       className={`font-sans text-sm text-overflow ${
//                         card.bgColor === "#FFFFFF" && "#FFB295"
//                           ? "text-black"
//                           : "text-[#FFFFFF]"
//                       }`}
//                     >
//                       {card.textBelow}
//                     </span>
//                   </div>
//                 </div>
//                 <div className="flex self-end justify-end">
//                   <img
//                     src={card.img2}
//                     alt={`Offer Card ${index + 1}`}
//                     className="w-32 h-auto object-cover"
//                   />
//                 </div>
//               </div>
//             </div>
//           ))}
//       </Slider>
//     </div>
//   );
// };

// export default PauseOnHover;

import React from 'react';
import Slider from 'react-slick';
import OfferCard1 from "../../assets/images/off1.png";
import OfferCard2 from "../../assets/images/off2.png";
import OfferCard3 from "../../assets/images/off3.png";
import OfferCard4 from "../../assets/images/off4.png";
import OfferCard5 from "../../assets/images/off5.png";
import OfferCardU1 from "../../assets/images/offu1.png";
import OfferCardU2 from "../../assets/images/offu2.png";
import OfferCardU3 from "../../assets/images/offu3.png";
import OfferCardU4 from "../../assets/images/offu4.png";
import OfferCardU5 from "../../assets/images/offu5.png";
import OfferCardU6 from "../../assets/images/offu6.png";
import { useNavigate } from 'react-router-dom';

const SliderComponent = () => {
  const navigate = useNavigate();
  const storedCode = localStorage.getItem('countryCode');
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const handleView = (id: string) => {
    navigate(`/product/${id}`);
  };
  return (
    <div className="w-full mx-auto py-10">
      {storedCode === "IN" && (<>
        <Slider {...settings}>

          <div className="px-2 md:px-4">
            <img src={OfferCard1} alt="Offer Card 1" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("6456")} />
          </div>
          <div className="px-2 md:px-4">
            <img src={OfferCard2} alt="Offer Card 2" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("3276")} />
          </div>
          <div className="px-2 md:px-4">
            <img src={OfferCard3} alt="Offer Card 3" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("7095")} />
          </div>
          <div className="px-2 md:px-4">
            <img src={OfferCard4} alt="Offer Card 3" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("8790")} />
          </div>
          <div className="px-2 md:px-4">
            <img src={OfferCard5} alt="Offer Card 3" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("7146")} />
          </div>


        </Slider>
      </>
      )}
      {storedCode === "UK" && (<>
        <Slider {...settings}>

          {/* <div className="px-2 md:px-4">
            <img src={OfferCardU1} alt="Offer Card 1" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("8864")} />
          </div> */}
          <div className="px-2 md:px-4">
            <img src={OfferCardU2} alt="Offer Card 2" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("8862")} />
          </div>
          <div className="px-2 md:px-4">
            <img src={OfferCardU3} alt="Offer Card 3" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("8861")} />
          </div>
          <div className="px-2 md:px-4">
            <img src={OfferCardU4} alt="Offer Card 3" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("8860")} />
          </div>
          <div className="px-2 md:px-4">
            <img src={OfferCardU5} alt="Offer Card 3" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("8859")} />
          </div>
          <div className="px-2 md:px-4">
            <img src={OfferCardU6} alt="Offer Card 3" className="w-full h-auto object-cover rounded-lg cursor-pointer" onClick={() => handleView("8863")} />
          </div>
        </Slider>
      </>)}
    </div>
  );
};

export default SliderComponent;

