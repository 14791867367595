import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveState } from '../utils/Storage';

interface ServingsState {
  [id: number]: number;
}

const initialState: ServingsState = {};

const servingsSlice = createSlice({
  name: 'servings',
  initialState,
  reducers: {
    increment: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state[id] = (state[id] || 1) + 1;
      saveState('servings', state);
    },
    decrement: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state[id] = Math.max((state[id] || 1) - 1, 1);
      saveState('servings', state);
    },
    setServings: (state, action: PayloadAction<{ id: number; servings: number }>) => {
      const { id, servings } = action.payload;
      state[id] = servings;
      saveState('servings', state);
    },
  },
});

export const { increment, decrement, setServings } = servingsSlice.actions;
export default servingsSlice.reducer;
