import React from "react";

const ShippingPolicy: React.FC = () => {
  return (
    <div className="p-6 md:p-10 lg:p-16 bg-gray-50 text-gray-800">
      <h1 className="text-3xl font-bold mb-6">Shipping Policy</h1>

      <div className="mb-8">
        <p className="mb-4 leading-relaxed">
          AnyFeast (“we” and “us”) is the operator of{" "}
          <a href="https://www.anyfeast.com" className="text-blue-500 underline">
            https://www.anyfeast.com
          </a>{" "}
          (“Website”). By placing an order through this Website you will be
          agreeing to the terms below. These are provided to ensure both parties are
          aware of and agree upon this arrangement to mutually protect and set
          expectations on our service.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">General</h2>
        <p className="mb-4 leading-relaxed">
          Subject to stock availability. We try to maintain accurate stock counts on
          our website but from time-to-time there may be a stock discrepancy and we
          will not be able to fulfill all your items at time of purchase. In this
          instance, we will fulfill the available products to you, and contact you
          about whether you would prefer to await restocking of the backordered item
          or if you would prefer for us to process a refund.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Shipping Cost</h2>
        <p className="mb-4 leading-relaxed">
          Shipping costs are calculated during checkout based on weight, dimensions
          and destination of the items in the order. Payment for shipping will be
          collected with the purchase. This price will be the final price for
          shipping cost to the customer.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Domestic Transit Time</h2>
        <p className="mb-4 leading-relaxed">
          In general, shipments are in transit for 2 – 3 days.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Next Day Delivery</h2>
        <p className="mb-4 leading-relaxed">
          Orders placed before 12 PM – GMT will be dispatched the next business day.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Change Of Delivery Address</h2>
        <p className="mb-4 leading-relaxed">
          For change of delivery address requests, we are able to change the address
          at any time before the order has been dispatched.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Items Out Of Stock</h2>
        <p className="mb-4 leading-relaxed">
          If an item is out of stock, we will dispatch the in-stock items immediately
          and send the remaining items once they return to stock.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Delivery Time Exceeded</h2>
        <p className="mb-4 leading-relaxed">
          If delivery time has exceeded the forecasted time, please contact us so
          that we can conduct an investigation.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Parcels Damaged In Transit</h2>
        <p className="mb-4 leading-relaxed">
          If you find a parcel is damaged in-transit, if possible, please reject the
          parcel from the courier and get in touch with our customer service. If the
          parcel has been delivered without you being present, please contact
          customer service with next steps.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Duties & Taxes</h2>
        <p className="mb-4 leading-relaxed">
          Sales tax has already been applied to the price of the goods as displayed
          on the website.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">
          Process for Parcel Damaged In-Transit
        </h2>
        <p className="mb-4 leading-relaxed">
          You may use our Service only for lawful purposes. You may not use our
          Site, App and our Platform in any way that breaches any applicable local,
          national or international law or regulation or to send, knowingly receive,
          upload, download, use or re-use any material which does not comply with
          our content standards below. You also agree not to access without
          authority, interfere with, damage or disrupt any part of our Site, App and
          our Platform or any network or equipment used in the provision of our
          Service.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Process for Parcel Lost In-Transit</h2>
        <p className="mb-4 leading-relaxed">
          We may from time to time provide certain features which allow you to
          interact through our Site, App and our Platform. Generally, we do not
          moderate any interactive service we provide although we may remove content
          in contravention of these Terms of Use. If we do decide to moderate an
          interactive service, we will make this clear before you use the service
          and normally provide you with a means of contacting the moderator, should
          a concern or difficulty arise.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Customer Service</h2>
        <p className="mb-4 leading-relaxed">
          For all customer service enquiries, please email us at{" "}
          <a href="mailto:contact@anyfeast.com" className="text-blue-500 underline">
            contact@anyfeast.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default ShippingPolicy;
