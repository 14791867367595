
import React from "react";
import { twMerge } from "tailwind-merge";
export type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  clearRight?: boolean;
  clearLeft?: boolean;
};
const Container: React.FC<Props> = ({
  children,
  className,
  clearRight = false,
  clearLeft = false,
  ...props
}) => {
  return (
    <div
      className={twMerge(
        `mx-auto w-full max-w-full ${
          clearRight
            ? "mr-0 md:pr-0 lg:max-w-container-right"
            : "lg:max-w-container"
        } ${
          clearLeft
            ? "ml-0 md:pl-0 lg:max-w-container-left"
            : "lg:max-w-container"
        }`,
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
//  `mx-auto px-4 md:px-5 w-full max-w-full ${
export default Container;