import React from "react";

interface ModalProps {
  isOpen: boolean;
  setSelectedCuisines: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCuisines: string[];
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  setSelectedCuisines,
  selectedCuisines,
}) => {
  if (!isOpen) return null;

  const handleCuisineChange = (cuisine: string) => {
    setSelectedCuisines((prevCuisines) =>
      prevCuisines.includes(cuisine)
        ? prevCuisines.filter((item) => item !== cuisine)
        : [...prevCuisines, cuisine]
    );
  };

  const handleClearAll = () => {
    setSelectedCuisines([]);
  };

  const handleApply = () => {
    console.log("Applied Cuisines: ", selectedCuisines);
    onClose();
  };

  const cuisinesList = [
    "Indian",
    "Chinese",
    "Italian",
    "French",
    "Mexican",
    "American",
    "English",
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-6 xl:w-1/3 lg:w-1/3 md:w-1/3 w-3/4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold font-sans text-[#313131]">
            Cuisines
          </h2>
          <button onClick={onClose}>
            <svg
              width="27"
              height="27"
              viewBox="0 0 47 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.0948 19.1221L19.2101 28.0067"
                stroke="black"
                strokeWidth="3.23"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.2101 19.1221L28.0948 28.0067"
                stroke="black"
                strokeWidth="3.23"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M30.6975 16.9258L16.9333 30.6899"
                stroke="black"
                strokeWidth="5.005"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.9333 16.9258L30.6975 30.6899"
                stroke="black"
                strokeWidth="5.005"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M23.8154 44.4539C35.218 44.4539 44.4616 35.2103 44.4616 23.8078C44.4616 12.4052 35.218 3.16162 23.8154 3.16162C12.4128 3.16162 3.16925 12.4052 3.16925 23.8078C3.16925 35.2103 12.4128 44.4539 23.8154 44.4539Z"
                stroke="black"
                strokeWidth="5.005"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <ul className="mt-4">
          {cuisinesList.map((cuisine) => (
            <li
              key={cuisine}
              className="flex items-center mt-6 font-sans text-[#313131] "
            >
            <input
  type="checkbox"
  className="mr-2 accent-[#0B6E27] "
  checked={selectedCuisines.includes(cuisine)}
  onChange={() => handleCuisineChange(cuisine)}
/>

              {cuisine}
            </li>
          ))}
        </ul>
        <div className="flex justify-end items-center mt-6">
  <button
    onClick={handleClearAll}
    className="text-[#313131] font-sans border border-gray-400 rounded-md px-4 py-2 mr-2"
  >
    Clear All
  </button>
  <button
    onClick={handleApply}
    className="bg-[#0B6E27] text-white font-sans rounded-md px-6 py-2 "
  >
    Apply
  </button>
</div>

      </div>
    </div>
  );
};

export default Modal;
