import React, { useEffect, useState } from "react";
import UpArrow from "../../../src/assets/images/uparrow.png";
import { useAppDispatch } from "../../hooks";
import { getRecipeIngredientAsync } from "../../services/reducers/reciepeReducer";
import { useNavigate } from "react-router-dom";

type Dish = {
  name: string;
  images: string;
  id: string;
};

type AccordionSectionProps = {
  title: string;
  subtitle: string;
  dishes: Dish[];
  isOpen: boolean;
  toggleOpen: () => void;
  isHomePage?: boolean;
};

const AccordionSection: React.FC<AccordionSectionProps> = ({
  title,
  subtitle,
  dishes,
  isOpen,
  toggleOpen,
}) => {
  const navigate = useNavigate();

  const handleView = (id: string) => {
    navigate(`/detail/${id}`);
  };

  return (
    <div className="lg:mt-3 mt-1 px-1">
      <button
        className="w-full flex justify-between items-center text-left"
        onClick={toggleOpen}
      >
        <div>
          <h3 className="xl:text-[16px] lg:text-[14px] md:text-[12px] font-semibold font-sans">{title}</h3>
          <p className="xl:text-xs lg:text-[12px] md:text-[10px] text-xs text-gray-400 font-sans">{subtitle}</p>
        </div>
        <span>{isOpen ? <img src={UpArrow} alt="Up Arrow" /> : "+"}</span>
      </button>
      {isOpen && (
        <div className="grid grid-cols-3 gap-4 pt-5">
          {dishes.map((dish, index) => (
            <div className="transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-red-100 hover:p-3 hover:rounded-lg">
              <button
                key={index}
                className="text-center"
                onClick={() => handleView(dish.id)}
              >
                <div className="flex flex-col justify-between">
                  <div className="flex-shrink-0">
                    <img
                      src={dish.images}
                      alt={dish.name}
                      className="rounded-full mx-auto xl:w-24 xl:h-24 lg:w-20 lg:h-20 md:w-14 md:h-14 w-14 h-14 object-cover"
                    />
                  </div>
                  <div>
                    {" "}
                    <p className="mt-2 font-sans xl:text-xs md:text-[10px]  xl:min-h-[40px] lg:min-h-[20px] md:min-h-[10px] min-h-[10px] lg:line-clamp-none md:line-clamp-1 line-clamp-1">{dish.name}</p>
                  </div>
                </div>
              </button>
            </div>
          ))}

        </div>
      )}
      <style>
        {`
          /* Only apply these styles for iPad and iPad mini */
          @media only screen 
          and (min-device-width: 768px) 
          and (max-device-width: 1024px) 
          and (-webkit-min-device-pixel-ratio: 1) {
            .ipad-home-layout {
              display: flex !important;
              overflow-x: auto;
              gap: 1rem;
              padding: 1rem;
              scroll-snap-type: x mandatory;
              -webkit-overflow-scrolling: touch;
            }

            .ipad-home-layout::-webkit-scrollbar {
              display: none;
            }

            .dish-item {
              flex: 0 0 auto;
              width: 200px;
              background: white;
              border-radius: 16px;
              padding: 1rem;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            }

            .dish-item img {
              width: 100%;
              height: 160px;
              object-fit: cover;
              border-radius: 12px;
            }

            .dish-item p {
              margin-top: 0.75rem;
              font-weight: 500;
              text-align: center;
            }

            /* First item styling */
            .dish-item:first-child {
              margin-left: 0;
            }

            /* Last item styling */
            .dish-item:last-child {
              margin-right: 1rem;
            }
          }

          /* iPad mini specific adjustments */
          @media only screen 
          and (min-device-width: 768px) 
          and (max-device-width: 834px)
          and (-webkit-min-device-pixel-ratio: 2) {
            .dish-item {
              width: 180px;
            }

            .dish-item img {
              height: 140px;
            }
          }
        `}
      </style>
    </div>
  );
};

type SameDishesProps = {
  recipeIds: string[];
  countryCode: any;
};

const App: React.FC<SameDishesProps> = ({ recipeIds, countryCode }) => {
  const [openSections, setOpenSections] = useState<boolean[]>(
    Array(recipeIds.length).fill(true)
  );
  const dispatch = useAppDispatch();
  const [ingredientDetails, setIngredientDetails] = useState<any[]>([]);

  useEffect(() => {
    getIngredientDetails();
  }, [recipeIds]);

  const getIngredientDetails = async () => {
    const request = {
      country: countryCode,
    };

    const promises = recipeIds.map((id) => {
      return dispatch(
        getRecipeIngredientAsync({ id, country: request.country })
      );
    });

    const responses = await Promise.all(promises);
    //console.log("API Responses:", responses);
    const allIngredients = responses.map((res) => res.payload.data);
    setIngredientDetails(allIngredients);
  };

  const toggleOpen = (index: number) => {
    setOpenSections((prev) => {
      const newOpenSections = [...prev];
      newOpenSections[index] = !newOpenSections[index];
      return newOpenSections;
    });
  };

  return (
    <div className="grid gap-8 py-2 px-6 ">

      {ingredientDetails.map((details, index) => (
        <AccordionSection
          key={index}
          title="Same Ingredients, New Dishes"
          subtitle="Recipes you can make with the same ingredients"
          dishes={details || []}
          isOpen={openSections[index]}
          toggleOpen={() => toggleOpen(index)}

        />
      ))}

    </div>
  );
};

export default App;