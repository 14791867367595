import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="p-6 md:p-10 lg:p-16 bg-gray-50 text-gray-800">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Access Data</h2>
        <p className="mb-4 leading-relaxed">
          When our website is accessed, so-called server log files are transmitted to us and stored on our server for a
          limited period of time (maximum six months). The following data is logged: IP address used, browser type and
          version, operating system, website visited, date and time of access, notification of successful access, amount
          of data sent in bytes, referrer URL (if access was made via a link). This information is used for statistical
          evaluations for the purpose of the operation, security and optimization of the offer. No personal evaluation
          is carried out. However, we reserve the right to evaluate this access data retrospectively in the event of
          justified suspicion of unlawful use.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Cookies</h2>
        <h3 className="text-xl font-bold mb-3">General Information and Consent</h3>
        <p className="mb-4 leading-relaxed">
          Cookies are used on this website. Cookies are small text files that are automatically created by your browser
          and stored on your end device (laptop, tablet, smartphone or similar). Information is stored in the cookie
          that is related to the specific end device used. However, this does not mean that we gain direct knowledge of
          your identity. Some of the cookies we use are deleted at the end of the browser session (so-called session
          cookies). These enable us to offer you, for example, the cross-page shopping basket display, in which you can
          see how many items are currently in your shopping basket and what your current purchase value is.
        </p>
        <p className="mb-4 leading-relaxed">
          Other cookies remain on your computer and enable us to recognize your computer on your next visit (so-called
          permanent or cross-session cookies). These cookies in particular serve, among other things, to make our offer
          more attractive to you. Thanks to these files, it is possible, for example, for you to receive information on
          the website www.anyfeast.com that is specifically tailored to your interests.
        </p>
        <p className="mb-4 leading-relaxed">
        According to legal requirements, the storage of information on end devices (desktops, mobile phones, tablets, etc.) – e.g. by setting cookies – and the retrieval of information from end devices (tracking) is only permitted if you have given your prior consent. However, consent does not have to be given if such storage/retrieval is necessary for the website to function. With regard to the data processing that is necessary for the operation of the website, you do not have the right to object.
        </p>
        <p className="mb-4 leading-relaxed">
        You can use the website www.anyfeast.com without data being retrieved from or stored on your end device for purposes that are not necessary for the website www.anyfeast.com to offer. For this reason, only “basic tracking” is activated when you use the website www.anyfeast.com unless you give your consent to further use.
        </p>
        <p className="mb-4 leading-relaxed">
        Similar to cookies are technologies known as “web beacons”, “pixels or “tags”. These technologies do a similar job to cookies, allowing website operators to count page views and understand how visitors interact with and respond to certain content
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Types Of Service</h2>
        <p className="mb-4 leading-relaxed">
          We use “first party cookies” and “third party cookies”. First party cookies are cookies placed by us. Third
          party cookies are cookies placed by others. Information collected by third party cookies will be shared with
          the relevant third party. Please refer to the relevant third party’s privacy notice for more details about the
          information they collect and how they use it.
        </p>
        <p className="mb-4 leading-relaxed">
        You should be aware that applications you use to access our Site, such as your web browser, may also place cookies on your device when visiting our Site, or other websites. An example of this would be where you sign in to Google Chrome using a Google Account. We have no control over these third party cookies, so you will need to manage these cookies in the settings of the relevant applications. For more general information on cookies and how they work, please visit All About Cookies.
        </p>
      </div>

      <div>
        <h2 className="text-2xl font-bold mb-4">How Long Do Cookies Last?</h2>
        <p className="mb-4 leading-relaxed">
          We use “first party cookies” and “third party cookies”. First party cookies are cookies placed by us. Third
          party cookies are cookies placed by others. Information collected by third party cookies will be shared with
          the relevant third party. Please refer to the relevant third party’s privacy notice for more details about the
          information they collect and how they use it. You should be aware that applications you use to access our
          Site, such as your web browser, may also place cookies on your device when visiting our Site, or other
          websites. An example of this would be where you sign in to Google Chrome using a Google Account. We have no
          control over these third party cookies, so you will need to manage these cookies in the settings of the
          relevant applications. For more general information on cookies and how they work, please visit All About
          Cookies.
        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">How Long Do Cookies Last?</h2>
        <p className="mb-4 leading-relaxed">
        You agree that AnyFeast may at any time and for any reason, including but not limited to breach of the Terms or the Privacy Policy, terminate or restrict or suspend your access to the platform, with or without prior notice, and without liability. If there is a suspicion of unlawful or illegal activity, AnyFeast may suspend your account immediately.
        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">What types of cookies are used on our site?
        </h2>
        <p className="mb-4 leading-relaxed">
        We use the following types of cookies:
        </p>
        <p className="mb-4 leading-relaxed">
        Cookies/Strictly Necessary Cookies
        – These cookies are those cookies which a website could not operate without. These include cookies such as login cookies and shopping cart cookies.        </p>
        <p className="mb-4 leading-relaxed">
        Functional Cookies
        – These cookies recognise you when you return to a website, remember your preferences and provide enhanced, more personalised features.        </p>
        <p className="mb-4 leading-relaxed">
        Performance Cookies –
        Performance cookies, along with other information, allow websites to calculate the aggregate number of people using a website and which features of a website are the most popular. This information is generally used to improve the website and the way visitors are able to move around it.        </p>
        <p className="mb-4 leading-relaxed">
        Social Media Cookies –
        These cookies are used to personalise your interaction with social media, such as Twitter, Facebook, and YouTube. Such cookies recognize users of those social media sites when you view social media content on the website. They also allow you to quickly share content across social media, through the use of simple “sharing” buttons. Some websites may also contain features that enable you to use your social media account to sign in and create/maintain an account with the website you are visiting and to enable the website operator to store your content. This involves the use of cookies.        </p>
        <p className="mb-4 leading-relaxed">
        Targeting Cookies –
        Targeting cookies (also known as advertising cookies) help us to deliver our adverts when you visit other websites, such as news websites. This is known as “retargeting”. This technique helps to ensure that the ads you receive are more relevant to you and your interests. These cookies help to measure the effectiveness of an ad and can also limit the number of times that you see an ad. These cookies are placed on your device by advertising networks that we and other advertisers work with to deliver ads. They remember what sites you visit and this information is shared with other ad networks to show you relevant ads. Is permission required to use cookies?        </p>
        <p className="mb-4 leading-relaxed">
        Essential/Strictly Necessary Cookies –
        We do not need your consent to store and access essential cookies on your device, although you can still block or delete them.        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">Other (“non-essential”) Cookies
        </h2>
        <p className="mb-4 leading-relaxed">
        – We may only store and access non-essential cookies on your device with your permission. You are not obliged to give consent to our use of non-essential cookies. If you do give your consent and then change your mind, you can still delete any cookies that have already been placed.
        </p>
        <p className="mb-4 leading-relaxed">
        Social Networks
        </p>
        <p className="mb-4 leading-relaxed">
        To ensure the best possible protection of your data when visiting our website, we only use links to the respective social networks. As a result, your data will only be transmitted to the social networks if you actively click on the respective buttons. If, on the other hand, the button is not clicked, there is no exchange between you and the social networks. For information on the scope of data collection and the further processing and use of the data by the social networks themselves, please refer to the privacy policies of the providers. What about other tracking tools, like web beacons? Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called “tracking pixels” or “clear gifs”). These are tiny graphics files that contain a unique identifier that enable us to recognize when someone has visited our Websites or opened an e-mail including them. This allows us, for example, to monitor the traffic patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of e-mail marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.
        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">What about Google Chrome cookies?

        </h2>
        <p className="mb-4 leading-relaxed">
        Separately to our use of Google Analytics cookies, when you access the Site via Google applications such as Google Chrome, Google may collect user information for the purpose of Google serving personalised advertising across your devices. Google is able place these advertising cookies on the devices used by visitors to the Site because we use Google services to run the Site.
        </p>
        <p className="mb-4 leading-relaxed">
        However these cookies are dropped for Google’s own purposes and as such we do not have any control over how or when they are dropped. You will need to opt out of receiving these cookies via your Google settings. For further information about these cookies please see Google’s Policy.
        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">What about Google Analytics?
        </h2>
        <p className="mb-4 leading-relaxed">
        If you do not want to be tracked by Google Analytics, you can install the Google Analytics opt-out browser add-on (but this will only work on certain browsers). For further information on Google Analytics and its use of cookies, please consult the Google Analytics cookie policy.

        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">Privacy Policy for Google
        </h2>
        <p className="mb-4 leading-relaxed">
        Sign In 1. Information We Collect: – When you sign in to AnyFeast using “Sign-in-With-Google,” we utilize Single Sign-On (SSO) via OAuth2. This allows us to access certain information associated with your Google account for authentication and authorisation purposes. – We only collect the name and email address associated with your Google account.

        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">2. How We Use Your Information:
        </h2>
        <p className="mb-4 leading-relaxed">
        – The name and email address collected from your Google account are solely used for authentication and authorisation purposes within AnyFeast. – Your information helps us verify your identity and ensure the security of your account. – We do not use your information for any other purposes without your explicit consent.

        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">3. Data Security:
        </h2>
        <p className="mb-4 leading-relaxed">
        – We implement industry-standard security measures to protect your information provided by Google from unauthorised access, alteration, disclosure, or destruction. – Access to your personal email and name is restricted to authorized personnel who need to know that information for the purposes outlined in this Privacy Policy.

        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">4. Third-Party Disclosure:
        </h2>
        <p className="mb-4 leading-relaxed">
        – We do not sell, trade, or otherwise transfer your personal information to outside parties. – Your information is only shared with third parties when necessary for providing our services, such as with Google for authentication purposes.

        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">5. Data Retention:
        </h2>
        <p className="mb-4 leading-relaxed">
        – We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">How do I refuse, block or delete cookies?
        </h2>
        <p className="mb-4 leading-relaxed">
        There are various ways that you can manage your cookie preferences but please be aware that in order to use some parts of our Site you will need to allow certain essential or functional cookies.
        </p>
        <p className="mb-4 leading-relaxed">
        If you block or subsequently delete those cookies, some aspects of our Site may not work properly and you may not be able to access all or part of our Site.
        </p>
        <p className="mb-4 leading-relaxed">
        Your options for managing your cookie preferences are set out below: When you access our Site, we will ask you to provide consent for our use of non-essential cookies. We may repeat this request on subsequent visits to our Site, for example if you delete cookies from your browser or we need to request new consents from you.
        </p>
        <p className="mb-4 leading-relaxed">
        Please be aware that withdrawing your consent does not remove the cookie from your device and, in the case of Social Media Cookies and Targeting Cookies, the cookie may still be used by third parties to show you personalised content and/or advertisements. Where cookies are essential to the operation of our Site, we can use these without your consent and they cannot be managed. Using your browser settings or other tools You can block the use of cookies generally (not just in respect of our Site) by activating the relevant settings in your browser. For more information on cookie management and blocking or deleting cookies for a wide variety of browsers, visit All About Cookies.
        </p>
        <p className="mb-4 leading-relaxed">
        Internet Explorer: Select the “Tools” menu and then “Internet Options.” Click on the “Privacy” tab. Select the appropriate setting.
        </p>
        <p className="mb-4 leading-relaxed">
        Disabling cookies in Google Chrome Click Settings {'>'} Advanced. Under Security and Privacy, select Content Settings. Click on “Cookies”.
        </p>
        <p className="mb-4 leading-relaxed">
        Disabling cookies in Safari Go to Preferences {'>'} Privacy. Click on “Remove all website data”. Disabling cookies in Firefox Select the “Tools” menu and then “Options.” Click on the “Privacy” icon. Click on the “Cookie” menu and select the desired options.
        </p>
        <p className="mb-4 leading-relaxed">
        Disabling cookies in Opera Opera 6.0 and later versions Go to the “File” {'>'} “Preferences” menu. Some cookies are dropped by third parties who operate the browser or application through which you access the Site.
        </p>
        <p className="mb-4 leading-relaxed">
        We do not have control of this and you will need to manage those cookie settings through the relevant third party application. If you do not want to be tracked by Google Analytics, you can install the Google Analytics opt-out browser add-on (but this will only work on certain browsers). For further information on Google Analytics and its use of cookies, please consult the Google Analytics cookie policy.
        </p>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">Changes
        </h2>
        <p className="mb-4 leading-relaxed">
        To ensure the best possible protection of your data when visiting our website, we only use links to the respective social networks. As a result, your data will only be transmitted to the social networks if you actively click on the respective buttons. If, on the other hand, the button is not clicked, there is no exchange between you and the social networks. For information on the scope of data collection and the further processing and use of the data by the social networks themselves, please refer to the privacy policies of the providers.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
