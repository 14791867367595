import axios from "axios";
import { API_URL } from "./constants";


const http = axios.create({
  baseURL: API_URL.INTEGRATION_API_URL,
  headers: {
    accept: "*/*",
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      console.error('No access token found. Canceling API request.');
      return Promise.reject(new Error('No access token available. Request canceled.'));
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const fetchData = async () => {
  try {
    const response = await http.get('/your-endpoint');
    console.log(response.data);
  } catch (error:any) {
    console.error('API call failed:', error.message);
  }
};

export { http };
