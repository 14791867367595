import { useEffect, useState } from "react";
import p1 from "../../../src/assets/images/p1.png";
import p2 from "../../../src/assets/images/p2.png";
import p3 from "../../../src/assets/images/p3.png";
import p4 from "../../../src/assets/images/p4.png";
import p5 from "../../../src/assets/images/p5.png";
import p6 from "../../../src/assets/images/p6.png";
import p7 from "../../../src/assets/images/p7.png";
import p9 from "../../../src/assets/images/p-9.png";
import emptyCart from "../../../src/assets/images/image.png";
import { useAppDispatch, useAppSelector } from "../../hooks";
import StepProgress from "./StepProgress";
//import SpecialOffersSection from "./SpecialOffer";
import RazorpayPayment from "../CheckOut/CheckOutWithRozarpay";
import CheckoutForm from "../CheckOut/CheckOut";
import { Elements } from "@stripe/react-stripe-js";
import { Constants } from "../../services/sharedService/constants";
import { loadStripe } from "@stripe/stripe-js";
import {
  addUpdateItemAsync,
  createOrderAsync,
  getAdressByUserIdAsync,
  getItemsByIdAsync,
  getSubscriptionInfoAsync,
} from "../../services/reducers/reciepeReducer";
import axios from "axios";
import { createCod } from "../../services/sharedService/AuthService";
import AddressModal from "../CheckOut/AddressModal";
import { useNavigate } from "react-router-dom";
import CouponPopUp from "./Coupons";

const PaymentSection = () => {
  //const [selectedUPI, setSelectedUPI] = useState<string | null>(null);
  const [ipAddress, setIpAddress] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  // const cartIngredients = useAppSelector((state) => state.cart);itemsList
  const [currentStep, setCurrentStep] = useState(2);
  const stripePromise = loadStripe(Constants?.loadStripe);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  //const cartIngredients = useAppSelector((state) => state.receipe.itemsList);
  const [cartIngredients, setCartIngredients] = useState<any>([]);
  const [cartAddress, setCartAddress] = useState<any>([]);
  const [extra, setExtra] = useState<any>([]);
  const [cartTotal, setCartTotal] = useState<any>([]);
  const userId = localStorage.getItem("userId");
  let itemCount = 0;
  const keys = Object.keys(cartIngredients);
  const firstKey = keys[0];
  //const result = firstKey?.split("-")[0];
  //const ids = Number(firstKey?.split("-")[1]);
  if (keys.length > 0) {
    itemCount = cartIngredients[keys[0]].length;
  }
  const [selectedAddress, setSelectedAddress] = useState(null);
  const address = cartAddress.find(
    (addr: any) => addr.address_id === selectedAddress
  );
  const carts = useAppSelector((state) => state.receipe.itemsList);
  const navigate = useNavigate();
  const [isCouponOpen, setIsCouponOpen] = useState(false);
  const [detail, setDetail] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [showLeftSection, setShowLeftSection] = useState(false);

  const handleConfirm = () => {
    navigate("/profile");
    window.scrollTo(0, 0);
  };
  //Coupon Navigation
  const handleCouponOpen = () => {
    setIsCouponOpen(true);
  };
  const handleCouponClose = () => {
    setIsCouponOpen(false);
  };

  // const navigateToProductPage = (productId: any, quantity: any) => {
  //   navigate(`/products/${productId}`, { state: { quantity } });
  // };
  // const navigateToProductPage = (id: string, selected: any) => {
  //   navigate(`/product/${id}`);
  // };
  //const storedCountry = localStorage.getItem("selectedCountry");
  const [selectedCountry, setSelectedCountry] = useState("India");
  const storedCode = localStorage.getItem("countryCode");

  useEffect(() => {
    const country = storedCode ?? "";
    if (userId) {
      dispatch(getItemsByIdAsync({ id: userId, country })).then((res) => {
        if (res.payload) {
          setCartIngredients(res.payload?.data?.cart?.cart || {});
          setCartTotal(res.payload?.data?.cart || 0);
          setExtra(res.payload?.data?.extras || []);
        }
      });
    }
  }, [userId]);

  useEffect(() => {
    getAddress();
  }, []);
  useEffect(() => {}, [detail]);
  useEffect(() => {
    if (userId) {
      dispatch(getSubscriptionInfoAsync(userId)).then((res) => {
        if (res.payload) {
          const subscriptionDateTimestamp = new Date(
            res.payload.data.subscription_date
          ).getTime();
          const currentDateTimestamp = res.payload.data.current_date;
          //const status = res.payload.data.subscription_status;
          // console.log(subscriptionDateTimestamp, "subscriptionDateTimestamp");
          // Calculate the difference in days
          const differenceInTime =
            currentDateTimestamp - subscriptionDateTimestamp;
          const differenceInDays = differenceInTime / (1000 * 3600 * 24);

          if (differenceInDays > 3650) {
            console.log("Difference in days is greater than 3650.");
            navigate(`/subscription`);
          }

          // if (subscriptionDateTimestamp > currentDateTimestamp) {
          //     console.log("Subscription date is greater than current date.");
          //   } else if (status === 0 && subscriptionDateTimestamp < currentDateTimestamp) {
          //     console.log("Current date is greater than subscription date.");
          //     navigate(`/subscription`);
          //   }
        }
      });
    }
  }, []);

  const getAddress = () => {
    if (userId) {
      dispatch(getAdressByUserIdAsync(userId)).then((res) => {
        if (res.payload) {
          setCartAddress(res.payload?.data);
        }
      });
    }
  };
  // const paymentApps = [
  //   { name: "Google Pay", image: p1 },
  //   { name: "Phone Pay", image: p2 },
  //   { name: "Cred UPI", image: p3 },
  //   { name: "Paytm", image: p4 },
  // ];

  // const otherApps = [
  //   { name: "Cash On Delivery", image: p7 },
  //   // { name: "AnyFeast Wallet", image: p8 },
  // ];

  const handleUpdateItemQuantity = (
    dishKey: string,
    ingredient: any,
    change: number
  ) => {
    if (!userId) {
      console.error("User ID is not available");
      return;
    }
    const newQuantity = ingredient.selected + change;
    if (newQuantity < 0) return;

    const dishId = Number(dishKey.split("-")[1]);
    const dishName = dishKey.split("-")[0];
    console.log("dishkey", dishKey);
    const request = {
      group: `${dishName}-${dishId}`,
      // group:`${groupName}-${groupId}`,
      // group:group,
      // group: `Shop- -1`,
      country: storedCode,
      items:
        newQuantity === 0
          ? [
              {
                ...ingredient,
                selected: 0,
              },
            ]
          : [
              {
                ...ingredient,
                selected: newQuantity,
              },
            ],
    };

    console.log("req sent", request);
    dispatch(addUpdateItemAsync({ id: userId, request }))
      .then((res) => {
        if (res.payload && res.payload.success) {
          const updatedCart = { ...cartIngredients };
          // const updatedCart = res.payload.cart;
          // updatedCart[dishKey] = updatedCart[dishKey].map((item: any) =>
          //     item.id === ingredient.id ? { ...item, selected: newQuantity } : item
          // );
          // seCartIngredients(updatedCart);
          //const shopKey = `Shop- -${dishId}`;
          // console.log(shopKey);
          if (newQuantity === 0) {
            updatedCart[dishKey] = updatedCart[dishKey].filter(
              (item: any) => item.id !== ingredient.id
            );
            if (updatedCart[dishKey].length === 0) {
              delete updatedCart[dishKey];
            }
          } else {
            updatedCart[dishKey] = updatedCart[dishKey] || [];
            updatedCart[dishKey] = updatedCart[dishKey].map((item: any) =>
              item.id === ingredient.id
                ? { ...item, selected: newQuantity }
                : item
            );
          }
          for (const shopKey in updatedCart) {
            if (updatedCart[shopKey].length === 0) {
              delete updatedCart[shopKey];
            }
          }

          const country = storedCode ?? "";
          if (userId) {
            dispatch(getItemsByIdAsync({ id: userId, country })).then((res) => {
              if (res.payload) {
                setCartIngredients(res.payload?.data?.cart?.cart || {});
                setCartTotal(res.payload?.data?.cart || 0);
                setExtra(res.payload?.data?.extras || []);
              }
            });
          }
        } else {
          console.error("failed tp update");
        }
      })
      .catch((err) => console.error("Error updating:", err));
  };

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIpAddress();
  }, []);

  // const handleUPISelection = (appName: string) => {
  //   setSelectedUPI(appName);
  //   createCodAsync();
  // };

  const handleView = () => {
    navigate(`/subscription`);
  };

  // const [showMessage, setShowMessage] = useState(false);
  const [emptyCartError, setEmptyCartError] = useState(false);

  const createCodAsync = async () => {
    // if (!selectedAddress) {
    //   setShowMessage(true);
    //   setTimeout(() => setShowMessage(false), 3000);
    //   return;
    // }
    if (
      !cartIngredients ||
      Object.keys(cartIngredients).length === 0 ||
      cartTotal.cart_value === 0
    ) {
      console.log("CART IS EMPTY");
      setEmptyCartError(true);
      setTimeout(() => setEmptyCartError(false), 3000);
      return;
    }
    if (selectedAddress) {
      const products = Object.keys(cartIngredients).flatMap(
        (dishKey: string) => {
          const dishId = Number(dishKey.split("-")[1]);
          const ingredients = cartIngredients[dishKey];

          return ingredients.map((ingredient: any) => ({
            ingredient_id: ingredient.id,
            name: ingredient.name,
            recipe_id: dishId,
            quantity: ingredient.selected,
          }));
        }
      );

      const requestBody = {
        order: {
          order_date: new Date().toISOString(),
          paid_date: new Date().toISOString(),
          phone: "",
          name: "",
          status: "pending",
          currency: "INR",
          ip_address: ipAddress,
          user_agent: "web",
          user_id: userId,
          shipping_address: address?.street_address,
          shipping_postcode: address?.postal_code,
          shipping_city: address?.city,
          shipping_state: "",
          shipping_country: address?.country,
          shipping_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
          order_subtotal: parseFloat(cartTotal?.cart_value.toFixed(2)),
          order_total: parseFloat(cartTotal?.cart_value.toFixed(2)),
          discount_total: detail.discount,
          coupon_id: detail.id,
        },
        products: products,
      };

      const createOrderResponse = await dispatch(
        createOrderAsync(JSON.stringify(requestBody))
      ).unwrap();

      if (
        createOrderResponse.success &&
        createOrderResponse.data &&
        createOrderResponse.data.orderId
      ) {
        const cartOrderId = createOrderResponse.data.orderId;
        console.log("Order created successfully. Order ID:", cartOrderId);

        const codResult = createCod(cartOrderId);
      }
      clearCart();
      handleConfirm();
    }
  };
  const clearCart = () => {
    setCartIngredients({});
    setCartTotal({ cart_value: 0 });
    // console.log("Cart has been cleared");
  };
  // console.log(detail, "12");

  const handleAddressSelect = (address: any) => {
    setSelectedAddress(address);
    setCurrentStep(3);
  };

  const calculateTotal = () => {
    const discount =
      detail?.discount_type === "percent"
        ? -(cartTotal?.cart_value * detail.discount) / 100
        : -detail.discount;

    const extraCharges = extra.reduce(
      (acc: any, curr: any) => acc + curr.charge,
      0
    );

    return (cartTotal?.cart_value || 0) + (discount || 0) + extraCharges;
  };

  useEffect(() => {
    setTotal(calculateTotal().toFixed(2));
  }, [cartTotal, detail, extra]);

  // useEffect(() => {
  //   setTotal(calculateTotal(cartIngredients).toFixed(2));
  // }, [cartIngredients, detail, extra]);

  // const handleAddressSelection = () => {
  //   if (selectedAddress) {
  //     setCurrentStep(3);
  //   }
  // };
  const handleShopView = () => {
    navigate(`/shop`);
  };
  const toggleSection = () => {
    setShowLeftSection((prevState) => !prevState);
  };
  const stripeMessage = () => {
    // if (!selectedAddress) {
    //   setShowMessage(true);
    //   setTimeout(() => setShowMessage(false), 3000);
    //   return;
    // }
    setIsModalOpen(true);
    // else{
    //   setIsModalOpen(true);
    // }
  }
  return (
    <div>
      {Object.keys(cartIngredients).length === 0 ? (
        <>
          <center>
            <div className="min-h-screen flex justify-center items-center pb-32 flex-col">
              <div className="flex flex-col justify-center items-center h-full text-center">
                <img
                  src={emptyCart}
                  alt="Main Banner"
                  className="w-3/4 sm:w-2/3 md:w-1/2 h-auto"
                />
                <p className="font-medium mt-6 font-sans text-xl">Your cart is empty</p>
                <span className="font-sans text-gray-600 mt-2 block text-lg">
                  You can go to shop page to add ingredients
                </span>
              </div>
              <button
                className="px-10 py-2 bg-[#0B6E27] text-white rounded-lg hover:bg-[#0B6E27] font-sans mt-6"
                onClick={() => handleShopView()}
              >
                Shop
              </button>
            </div>
          </center>
        </>
      ) : (
        <>
          <div className="mt-8 lg:block md:block xl:block hidden">
            <div className="mb-5">
              <StepProgress currentStep={currentStep} />
            </div>
          </div>
          <div className="flex gap-6 px-4 xl:px-8 lg:px-8 md:px-8 py-6">
            {/* Left Section: Payment and Address */}
            <div className={`lg:w-2/3 md:w-2/3 xl:w-2/3 w-full space-y-6 lg:block md:block xl:block ${showLeftSection ? 'hidden' : 'block'}`}>
              {/* Shipping Address */}
              {/* <div className="bg-white p-4 rounded-lg border shadow-custom-red font-sans">
                        <h3 className="text-lg pb-4">Shipping Address</h3>

                        <button className="text-[#202020] mt-2" onClick={()=> setAddressModalOpen(!isAddressModalOpen)}>+ Add New Address</button>
                    </div> */}
              <div className="bg-white p-4 rounded-lg border shadow-custom-red font-sans">
                <h3 className="text-lg pb-8">Select Delivery Address</h3>

                {/* Example address cartAddress */}
                {cartAddress.filter((address: any) => {
                  const countryMappings: { [key: string]: string[] } = {
                    IN: ["IN", "India"],
                    UK: ["UK", "United Kingdom"],
                  };

                  if(storedCode){
                  const normalizedSelectedCountry = Object.keys(countryMappings).find(key =>
                    countryMappings[key].includes(storedCode)
                  );

                  return (
                    normalizedSelectedCountry &&
                    countryMappings[normalizedSelectedCountry].includes(address.country)
                  );

                }
                }).map((address: any, index: number) => (
                  <div
                    className="flex items-center mb-8"
                    key={address.address_id}
                  >
                    <input
                      type="radio"
                      name="address"
                      className="mr-2 w-5 h-5 accent-[#0B6E27]"
                      // checked={selectedAddress === address.address_id}
                      checked={selectedAddress === address.address_id || (index === 0 && !selectedAddress)}
                      onChange={() => handleAddressSelect(address.address_id)}
                    />
                    <label className="text-[#202020]">
                      {address.flat_no ? `${address.flat_no}, ` : ""}
                      {address.street_address
                        ? `${address.street_address}, `
                        : ""}
                      {address.city}, {address.state_province},{" "}
                      {address.postal_code}, {address.country}
                    </label>
                  </div>
                ))}


                {/* Add New Address Button */}
                <button
                  className="p-3 rounded-lg text-lg"
                  onClick={() => setAddressModalOpen(!isAddressModalOpen)}
                >
                  <div className="flex items-center space-x-3">
                    <span className="text-3xl leading-none">+</span>
                    <span className="leading-none">Add New Address</span>
                  </div>
                </button>
              </div>

              {/* <div className="bg-white p-4 rounded-lg border shadow-custom-red font-sans">
            <p className="text-[#313131] text-2xl font-medium">
              Your cart contains heavier items. For bulky orders, our team may
              need to contact you to arrange delivery after purchase. We
              appreciate your understanding.
            </p>
          </div> */}
              {/* Payment Methods */}
              <div className="bg-white p-4 rounded-lg border shadow-custom-red font-sans">
                {storedCode == 'IN' && ( 
                  <>
                <h3 className="text-lg font-semibold text-[#000000] mt-2">
                  Pay Using UPI App
                </h3>
                <div className="flex flex-col py-4">
                  {/* {paymentApps.map((app) => (
                                <label
                                    key={app.name}
                                    className="flex items-center justify-between border-dashed border-b p-4"
                                >
                                    <div className="flex items-center">
                                        <img src={app.image} alt={app.name} className="w-6 h-6 mr-4" />
                                        <span className="text-[#000000]">{app.name}</span>
                                    </div>
                                    <input
                                        type="radio"
                                        name="upi"
                                        value={app.name}
                                        checked={selectedUPI === app.name}
                                        onChange={(e) => setSelectedUPI(e.target.value)}
                                        className="text-[#0B6E27] form-radio h-4 w-4 border-gray-300 focus:ring-0 checked:text-[#0B6E27]"
                                    />
                                </label>
                            ))} */}
                  <div className="flex items-center justify-between px-4">
                    {/* Add Another UPI ID */}
                    <div className="flex items-center">
                      <img src={p1} alt="gpay" className="w-6 h-6 mr-4" />
                      <span className="text-[#000000]">Google Pay</span>
                    </div>
                    {/* <span className="text-[#000000] font-sans"> Google Pay</span> */}
                    <RazorpayPayment
                      cartTotal={cartTotal}
                      price={cartTotal?.cart_value}
                      cartIngredients={cartIngredients}
                      ipAddress={ipAddress}
                      address={address}
                      selectedAddress={selectedAddress}
                      detail={detail}
                      countryCode={storedCode}
                    />
                    {/* <img src={p5} alt="Add UPI ID" className="w-6 h-6" onClick={handleCreateOrder} /> */}
                  </div>
                </div></> )}
              

                {storedCode == 'UK' && (<div className="flex flex-col mt-2">
                  <h3 className="text-lg font-semibold text-[#000000] font-sans">
                    Pay Using Card{" "}
                  </h3>

                  <div className="flex items-center justify-between px-4 py-4">
                    <div className="flex items-center">
                      <img src={p6} alt="Add UPI ID" className="w-6 h-6 mr-4" />
                      <span className="text-[#000000] font-sans">
                        Credit/Debit Card
                      </span>
                    </div>
                    <img
                      src={p5}
                      alt="Add UPI ID"
                      className="w-6 h-6"
                      onClick={stripeMessage}
                    />
                    {/* {showMessage && (
                        <div
                          style={{
                            position: "fixed",
                            bottom: "20px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            backgroundColor: "red",
                            color: "white",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          Please select an address.
                        </div>
                      )} */}
                  </div>
                </div>)}

                {/* Card Payment and Other Payment Methods */}
                <div className="bg-white font-sans mt-2">
                  <h3 className="text-lg font-semibold text-[#000000]">
                    Other Payment Options
                  </h3>
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between px-4 py-4">
                      <div className="flex items-center">
                        <img
                          src={p7}
                          alt="Add UPI ID"
                          className="w-6 h-6 mr-4"
                        />
                        <span className="text-[#000000] font-sans">
                          Cash On Delivery
                        </span>
                      </div>
                      <img
                        src={p5}
                        alt="Add UPI ID"
                        className="w-6 h-6"
                        onClick={() => createCodAsync()}
                      />
                      {/* {showMessage && (
                        <div
                          style={{
                            position: "fixed",
                            bottom: "20px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            backgroundColor: "red",
                            color: "white",
                            padding: "10px 20px",
                            borderRadius: "5px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          Please select an address.
                        </div>
                      )} */}
                    </div>
                    {/* {otherApps.map((app) => (
                                    <label
                                        key={app.name}
                                        className="flex items-center justify-between border-dashed border-b"
                                    >
                                        <div className="flex items-center">
                                            <img src={app.image} alt={app.name} className="w-6 h-6 mr-4" />
                                            <span className="text-[#000000]">{app.name}</span>
                                        </div>
                                        <input
                                            type="radio"
                                            name="upi"
                                            value={app.name}
                                            checked={selectedUPI === app.name}
                                            //onChange={(e) => setSelectedUPI(e.target.value)}
                                            onChange={() => handleUPISelection(app.name)}
                                            className="text-[#0B6E27] form-radio h-4 w-4 border-gray-300 focus:ring-0 checked:text-[#0B6E27]"
                                        />
                                    </label>
                                ))} */}
                  </div>
                </div>
              </div>
              <button
                className="w-full py-2 bg-[#0B6E27] text-white rounded-lg hover:bg-[#0B6E27] font-sans mt-6 lg:hidden md:hidden xl:hidden block"
                onClick={toggleSection}
              >
               Back
              </button>
            </div>
            
            {/* Right Section: Cart Items */}
            <div className={`lg:w-1/3 md:w-1/3 w-full lg:block md:block xl:block ${showLeftSection ? 'block' : 'hidden'}`}>
              <div className="bg-white rounded-lg border shadow-custom-red font-sans mb-4">
                <div className="bg-[#440508] text-white text-center py-2 rounded-t-lg">
                  <span className="text-sm font-medium">
                    Unlock Total Benefits Upto 20% off on this order
                  </span>
                </div>

                {/* Premium Section */}
                <div className="flex justify-between items-center p-4 border-dashed border-b font-sans">
                  <div>
                    <h2 className="text-red-700 font-bold text-lg">
                      AnyFeast Premium
                    </h2>
                  </div>
                  <div onClick={() => handleView()}>
                    <a
                      href="#"
                      className="text-red-700 font-medium flex items-center"
                    >
                      Know more
                      <svg
                        className="ml-2"
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1.26074L6 5.99992L1 10.7391"
                          stroke="#C30F16"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                {/* Benefits Section */}
                <div className="p-4 font-sans">
                  <h3 className="font-bold text-lg underline">Benefits</h3>
                  <ul className="list-disc pl-5 mt-2">
                    <li>20% discount on all meal kit orders.</li>
                    <li>Free Delivery.</li>
                  </ul>
                </div>

                {/* See All Link */}
                <div className="flex justify-end p-4 font-sans">
                  <a
                    href="#"
                    className="text-red-700 font-medium flex items-center"
                  >
                    See All
                    <svg
                      className="ml-2"
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1.26074L6 5.99992L1 10.7391"
                        stroke="#C30F16"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div
                className="bg-white p-4 rounded-lg border shadow-custom-red font-sans cursor-pointer"
                onClick={handleCouponOpen}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img src={p9} alt="Add UPI ID" className="w-6 h-6 mr-4" />
                    <h3 className="text-lg">View coupons & Offers</h3>
                  </div>
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L1 11"
                      stroke="#313131"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <CouponPopUp
                isOpen={isCouponOpen}
                onClose={handleCouponClose}
                setDetail={setDetail}
              />

              <div className="bg-white p-4 rounded-lg border shadow-custom-red mt-4">
                {(Object.keys(cartIngredients).map((dishKey: string) => {
                    const dishName = dishKey.split("-")[0];
                    //const dishId = Number(dishKey.split("-")[1]);
                    const ingredients = cartIngredients[dishKey];

                    return (
                      <div key={dishKey} className="mb-6">
                        <div className="flex items-center gap-4 mb-4">
                          <span className="flex-grow border-t-2 border-gray-300 ml-8"></span>
                          <h3 className="text-md lg:text-lg xl:text-lg md:text-lg font-semibold text-center">
                            {ingredients.length} items for {dishName}
                          </h3>
                          <span className="flex-grow border-t-2 border-gray-300 mr-8"></span>
                        </div>
                        <ul className="space-y-6">
                          {ingredients.map((ingredient: any, index: number) => (
                            <li
                              key={index}
                              className="flex items-center justify-between"
                            >
                              <div className="w-10 h-10 lg:w-16 lg:h-16 xl:w-16 xl:h-16 md:w-16 md:h-16">
                                <img
                                  src={ingredient.image}
                                  alt={ingredient.name}
                                  className="w-full h-full object-cover rounded-lg"
                                />
                              </div>

                              <div className="flex-1 mx-4 pl-8">
                                <h3 className="text-sm lg:text-lg xl:text-lg md:text-lg font-semibold text-[#253D4E] pb-2">
                                  {ingredient.name}
                                </h3>
                                <p className="text-[#828282] font-sans pb-2 text-xs lg:text-md xl:text-md md:text-md">
                                  Pack of {ingredient.quantity}
                                </p>
                                <div className="flex items-center space-x-2">
                                  <span className="text-[#3BB77E] text-lg font-bold pb-2 font-sans">
                                    {storedCode === "IN" ? "₹" : "£"}
                                    {ingredient.price}
                                  </span>
                                  {ingredient.revised_price > 0.0 && (
                                    <span className="text-[#ADADAD] line-through pb-2 font-sans">
                                      {storedCode === "IN" ? "₹" : "£"}
                                      {ingredient.revised_price}
                                    </span>
                                  )}
                                </div>
                              </div>

                              {/* Quantity Selector */}
                              <div className="flex items-center bg-[#0B6E27] text-white rounded-lg p-2 font-sans">
                                <button
                                  onClick={() =>
                                    handleUpdateItemQuantity(
                                      dishKey,
                                      ingredient,
                                      -1
                                    )
                                  }
                                  className="px-2 py-1"
                                  aria-label="Decrease"
                                >
                                  -
                                </button>

                                <span className="lg:px-4 md:px-4 xl:px-4 px-1">
                                  {ingredient.selected}
                                </span>
                                <button
                                  onClick={() =>
                                    handleUpdateItemQuantity(
                                      dishKey,
                                      ingredient,
                                      1
                                    )
                                  }
                                  className="px-2 py-1"
                                  aria-label="Increase"
                                >
                                  +
                                </button>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })
                )}
              </div>

              <div className="bg-white p-6 rounded-lg border shadow-custom-red mt-4 font-sans">
                <div className="flex justify-between items-center mb-4">
                  <span className="text-[#787878]">Subtotal</span>
                  <span className="text-[#313131]">
                    {storedCode === "IN" ? "₹" : "£"}
                    {cartTotal?.cart_value}
                  </span>
                </div>

                {/* <div className="flex justify-between items-center mb-4">
                            <span className="text-[#787878]">Delivery Fee</span>
                            <div className="flex items-center">
                                <span className="text-[#000000] line-through mr-2">₹200</span>
                                <span className="text-[#0B6E27] font-semibold">FREE</span>
                            </div>
                        </div> */}
                {extra.map((extra: any, index: number) => (
                  <div
                    key={index}
                    className="flex justify-between items-center mb-4"
                  >
                    <span className="text-[#787878]">{extra.name}</span>
                    <span className="text-[#000000]">
                      {storedCode === "IN" ? "₹" : "£"}
                      {extra.charge}
                    </span>
                  </div>
                ))}
                {detail && (
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-[#787878]">Coupon discount</span>
                    <span className="text-[#000000]">
                      {storedCode === "IN" ? "₹" : "£"}
                      {detail.discount_type === "percent"
                        ? (cartTotal?.cart_value * detail.discount) / 100
                        : detail.discount}
                    </span>
                  </div>
                )}
                {detail ? (
                  <div className="flex justify-between items-center font-semibold text-xl mt-6">
                    <span className="text-[#313131]">Total</span>
                    <span className="text-[#313131] font-bold">
                      {storedCode === "IN" ? "₹" : "£"} {total}
                    </span>
                  </div>
                ) : (
                  <div className="flex justify-between items-center font-semibold text-xl mt-6">
                    <span className="text-[#313131]">Total</span>
                    <span className="text-[#313131] font-bold">
                      {storedCode === "IN" ? "₹" : "£"}
                      {cartTotal?.cart_value +
                        extra.reduce(
                          (acc: any, curr: any) => acc + curr.charge,
                          0
                        )}
                    </span>
                  </div>
                )}
              </div>
              <button
                className="w-full py-2 bg-[#0B6E27] text-white rounded-lg hover:bg-[#0B6E27] font-sans mt-6 lg:hidden md:hidden xl:hidden block"
                onClick={toggleSection}
              >
               {/* {showLeftSection ? 'Back' : 'Pay Now'} */}
               Pay Now
              </button>
            </div>
          </div>
        </>
      )}
      {/* <div>
                <SpecialOffersSection />
            </div> */}
      {isAddressModalOpen && (
        <AddressModal
          isOpen={isAddressModalOpen}
          onClose={() => setAddressModalOpen(false)}
          getAddress={getAddress}
        />
      )}
      <Elements stripe={stripePromise}>
        {isModalOpen && (
          <CheckoutForm
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            cartTotal={cartTotal}
            price={cartTotal?.cart_value}
            cartIngredients={cartIngredients}
            ipAddress={ipAddress}
            address={address}
            selectedAddress={selectedAddress}
            detail={detail}
            countryCode={storedCode}
          />
        )}
      </Elements>
    </div>
  );
};

export default PaymentSection;
