import { useEffect, useState } from "react";
import banner1 from "../../../src/assets/images/BG.png";
import banner from "../../../src/assets/images/Bannar.png";
import banner2 from "../../../src/assets/images/Group 1321315162.png";
import Typography from "../Typography";
import arrow from "../../../src/assets/images/arrow.png";
import { Link } from "react-router-dom";
import uk from "../../../src/assets/images/BgLeft.png";
const Banner = () => {
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    const storedCountry = localStorage.getItem("selectedCountry");
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  }, [selectedCountry]);
  const bannerToDisplay =
    selectedCountry === "India"
      ? banner
      : selectedCountry === "UK"
      ? banner2
      : null;
  return (
    <div className="grid grid-cols-1 h-full mb-8 p-4 lg:p-12 lg:grid-cols-12">
      <div className="col-span-8 relative h-full mr-0 lg:mr-8 lg:col-span-8">
        {bannerToDisplay && (
          <img
            src={bannerToDisplay}
            alt="Main Banner"
            className="w-full h-auto sm:w-full"
          />
        )}
     <div className="absolute inset-0 flex flex-col justify-center items-start lg:p:12">
  <Typography
    variant="h1"
    className="font-bold 
      lg:text-3xl 
      md:text-2xl 
      text-sm 
      xs:text-xs  // Added for extra small screens
      pb-8 
      font-sans"
  >
    Are you Bored of{" "}
    <span className="text-[#FF0000] font-sans">Bland Recipe Kit</span>
    <br />
    Try AnyFeast{" "}
    <span className="text-[#0B6E27] font-sans">
      Authentic and tasty
    </span>
  </Typography>

  <div className="flex items-center mt-1 lg:mt-4 mb-5 lg:mb-0">
    <div className="
      !h-16 
      !w-[0.1675rem] 
      md:!h-20 
      lg:!h-20 
      !rounded-lg 
      !left-0 
      !top-3/4 
      lg:!top-1/2 
      -translate-y-1 
      md:-translate-y-5 
      lg:-translate-y-6  
      bg-[#FF8A00] 
      font-sans"
    ></div>
    
    <Typography
      variant="h4"
      className="
        font-medium 
        lg:font-bold 
        text-xs 
        md:text-xl 
        xs:text-2xs  // Added for extra small screens
        pb-2 
        md:pb-12 
        pl-2 
        lg:pl-4 
        md:pl-3 
        font-sans"
    >
      Sale up to{" "}
      <span className="
        bg-[#FF8A00] 
        p-1 
        lg:p-2 
        text-xs 
        md:text-sm 
        xs:text-2xs  // Added for extra small screens
        lg:text-sm 
        rounded-md 
        font-sans"
      >
        30% OFF
      </span>
      <br />
      <span className="
        mt-3 
        lg:mt-6 
        block 
        font-sans 
        xs:text-2xs  // Added for extra small screens
      ">
        Free shipping on all your orders.
      </span>
    </Typography>
  </div>

  <Link to="/persona/:id">
    <button className="
      bg-white 
      text-[#313131] 
      border 
      border-slate-300 
      hover:border-[#FF0000] 
      px-5 
      lg:px-12 
      py-2 
      lg:py-4 
      rounded-lg 
      shadow-lg 
      hover:shadow-2xl 
      transition-shadow 
      duration-300 
      font-medium 
      lg:font-semibold 
      lg:text-lg 
      md:text-lg 
      text-sm 
      xs:text-2xs  // Added for extra small screens
    ">
      Explore Recipes
    </button>
  </Link>
</div>
      </div>

      <div className="hidden lg:block col-span-4 h-full">
        <div className="flex flex-col justify-between h-full flex-grow">
          <div
            className="h-full flex-grow w-full mb-8 rounded-lg"
            style={{
              background: `url(${banner1})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <img src={banner1} alt="Banner 1" className="w-full h-auto" /> */}
            <div className="flex flex-col justify-start items-start">
              <div className="p-10">
                <Typography variant="h4" className="text-md font-sans">
                  WINTER SALE
                </Typography>
                <Typography
                  variant="h1"
                  className="font-bold text-2xl pb-4 font-sans"
                >
                  35% OFF
                </Typography>
                <Typography variant="h6" className="pb-4 font-sans">
                  Exotic Fruit & Vegetable
                </Typography>
                <div className="flex items-center">
                  <Link to="/shop">
                    <Typography
                      variant="h6"
                      className="text-[#00B207] font-sans"
                    >
                      Shop Now
                    </Typography>
                  </Link>
                  <span className="pl-2">
                  <Link to="/shop">
                      <img src={arrow} alt="arrow"></img>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="h-full flex-grow w-full"
            style={{
              background: `url(${uk})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <img src={banner2} alt="Banner 2" className="w-full h-auto" /> */}
            <div className="flex flex-col justify-between items-center">
              <div className="">
                <Typography
                  variant="h4"
                  className="text-md pt-20 pl-32 font-sans"
                >
                  BEST DEAL
                </Typography>
              </div>
              <div className="pl-28 pt-2">
                <Typography
                  variant="h1"
                  className="font-bold text-center text-xl font-sans"
                >
                  Authentic Meal Kit Deal of <br></br>the Month
                </Typography>
              </div>
              <div className="flex items-center pl-28 pt-8 font-sans">
                <Link to="/shop"><Typography variant="h6" className="text-[#00B207]">
                  Shop Now
                </Typography></Link>
                <span className="pl-2">
                <Link to="/shop">
                    <img src={arrow} alt="arrow"></img>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;