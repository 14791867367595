import React, { useState, useEffect } from "react";
import bokeh from "../../../src/assets/images/ChristmasBokeh.png";


const ChristmasBanner = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const targetDate = new Date("2024-12-21T00:00:00");
    const now = new Date();
    let difference = targetDate.getTime() - now.getTime();

    if (difference <= 0) {
      const countdownEndDate = new Date(now);
      countdownEndDate.setHours(now.getHours() + 72); 
      difference = countdownEndDate.getTime() - now.getTime();
    }

    const totalDays = Math.floor(difference / (1000 * 60 * 60 * 24));
    const totalHours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const totalMinutes = Math.floor((difference / (1000 * 60)) % 60);
    const totalSeconds = Math.floor((difference / 1000) % 60);

    return {
      totalDays,
      hours: totalHours,
      minutes: totalMinutes,
      seconds: totalSeconds,
      difference,
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); 
  }, []);

  return (
    <div
      className="flex items-center justify-center min-h-[60px] px-4 lg:px-12 bg-cover bg-center"
      style={{ backgroundImage: `url(${bokeh})` }}
    >
      <div className="flex flex-col lg:flex-row items-center lg:space-x-6 p-2 rounded-lg space-y-4 lg:space-y-0">
        {/* Banner Text */}
        <p className="text-white text-base sm:text-lg lg:text-2xl font-semibold text-center lg:text-left whitespace-normal lg:whitespace-nowrap">
          Hurry! Our recipe kits are running out fast. Get yours delivered to your
          doorstep.
        </p>

        {/* Countdown Timer */}
        <div className="flex space-x-4">
          {timeLeft.totalDays > 0 ?(
            <>
             <div className="flex flex-col items-center justify-center bg-green-700 text-white w-24 h-12 rounded">
            <span className="text-lg sm:text-2xl font-bold animate-pulse">
              {/* {timeLeft.totalDays < 10 ? `0${timeLeft.totalDays}` : timeLeft.totalDays} */}
            {timeLeft.totalDays}
            </span>
            <span className="text-[10px] sm:text-xs">DAYS</span>
          </div>

            </>
          ):(
            <>
            {/* Hours */}
          <div className="flex flex-col items-center justify-center bg-green-700 text-white w-12 h-12 sm:w-16 sm:h-14 rounded">
            <span className="text-lg sm:text-2xl font-bold animate-pulse">
              {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}
            </span>
            <span className="text-[10px] sm:text-xs">HOURS</span>
          </div>

          {/* Minutes */}
          <div className="flex flex-col items-center justify-center bg-green-700 text-white w-12 h-12 sm:w-16 sm:h-14 rounded">
            <span className="text-lg sm:text-2xl font-bold animate-pulse">
              {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}
            </span>
            <span className="text-[10px] sm:text-xs">MINUTES</span>
          </div>

          {/* Seconds */}
          <div className="flex flex-col items-center justify-center bg-green-700 text-white w-12 h-12 sm:w-16 sm:h-14 rounded">
            <span className="text-lg sm:text-2xl font-bold animate-pulse">
              {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
            </span>
            <span className="text-[10px] sm:text-xs">SECONDS</span>
          </div></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChristmasBanner;
