
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveState, loadState } from '../utils/Storage'; 
import { Ingredient } from '../utils/common';

interface QuantitiesState {
  quantities: { [id: number]: { ingredient: Ingredient; quantity: number } };
}

// const initialState: QuantitiesState = {
//   quantities: {},
// };

const loadQuantitiesFromStorage = (): { [id: number]: { ingredient: Ingredient; quantity: number } } => {
  const savedQuantities = loadState<{ [id: number]: { ingredient: Ingredient; quantity: number } }>('quantities') || {};
  return savedQuantities;
};

const quantitiesSlice = createSlice({
  name: 'quantities',
  initialState: {
    quantities: loadQuantitiesFromStorage(),
  },
  reducers: {
    updateQuantity: (state, action: PayloadAction<{ id: number; value: number }>) => {
      const { id, value } = action.payload;

      if (state.quantities[id]) {
        state.quantities[id].quantity += value;
        if (state.quantities[id].quantity <= 0) {
          delete state.quantities[id];
        }
        saveState('quantities', state.quantities);
      }
    },

    removeQuantity: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;

      if (state.quantities[id]) {
        delete state.quantities[id];
        saveState('quantities', state.quantities);
      }
    },

    setQuantities: (state, action: PayloadAction<{ id: number; ingredient: Ingredient; quantity: number }>) => {
      const { id, ingredient, quantity } = action.payload;
      state.quantities[id] = { ingredient, quantity };
      saveState('quantities', state.quantities);
    },
  },
});

export const { setQuantities, updateQuantity, removeQuantity } = quantitiesSlice.actions;
export default quantitiesSlice.reducer;
