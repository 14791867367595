import './App.css';
import AppContainer from './AppContainer';
import { store } from './store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <GoogleOAuthProvider clientId="471092554314-1ffc3j97au2oe6tqkltt4rk011024n7u.apps.googleusercontent.com">
      <Provider store={store}>
        <BrowserRouter>
          <AppContainer />
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
    
  );
}

export default App;
