import React, { useEffect, useState } from "react";
import "../../App.css";
import fruits1 from '../../assets/images/fruits1.png';
import fruits2 from '../../assets/images/fruits2.png';
import fruits3 from '../../assets/images/fruits3.png';
import fruits4 from '../../assets/images/fruits4.png';
import fruits5 from '../../assets/images/fruits5.png';
import CaretCircleRight from "../../assets/images/CaretCircleRight.png";
import { Link, useNavigate } from "react-router-dom";
import { addUpdateItemAsync, getExoticListAsync, getItemsByIdAsync } from "../../services/reducers/reciepeReducer";
import { useAppDispatch } from "../../hooks";
import { capitalizeWords } from "../../utils/common";
import LoginModal from "../Home/LoginModal";

interface Product {
  id: number;
  name: string;
  description: string;
  price: number;
  originalPrice: number;
  discount: number;
  imageUrl: string;
}

interface ProductTrialProps {
  title: string;
}

const products: Product[] = [
  {
    id: 1,
    name: "B Natural Mixed Fruit Juice",
    description: "(Buy 1 Get 1 FREE)",
    price: 100,
    originalPrice: 110,
    discount: 50,
    imageUrl: fruits1,
  },
  {
    id: 2,
    name: "Hen Fruit White Protein",
    description: "Rich Eggs 10 Pc",
    price: 110,
    originalPrice: 120,
    discount: 40,
    imageUrl: fruits2,
  },
  {
    id: 3,
    name: "Go Cheese Slices",
    description: "200 g",
    price: 140,
    originalPrice: 160,
    discount: 24,
    imageUrl: fruits3,
  },
  {
    id: 4,
    name: "Soan Papdi by Charlie's",
    description: "200 g",
    price: 51,
    originalPrice: 96,
    discount: 41,
    imageUrl: fruits4,
  },
  {
    id: 5,
    name: "Cremica Chocolate Thicker",
    description: "Syrup",
    price: 194,
    originalPrice: 225,
    discount: 20,
    imageUrl: fruits5,
  },
];

const ProductTrial: React.FC<ProductTrialProps> = ({ title }) => {
  const storedCountry = localStorage.getItem('selectedCountry');
  const storedCode = localStorage.getItem('countryCode');
  const dispatch = useAppDispatch();
  const [categoriesDetail, setCategoriesDetail] = useState([]);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleView = (id: string) => {
    navigate(`/product/${id}`);
  };
  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    const country = storedCode;
    if (country) {
      dispatch(getExoticListAsync(country)).then((res) => {
        if (res.payload) {
          setCategoriesDetail(res.payload.data);
        }
      });
    }
  };

  const handleAdd = (productId: number, ingredient: any) => {
    if (!userId) {
      console.error("User ID is not available");
      setIsModalOpen(true);
      return;
    }
    if (userId && storedCode) {
      //const newQuantity = (prev[productId] || 0) + 1;
      const request = {
        // group: `Shop- -1`,
        group: `Shop-0`,
        country: storedCode,
        items: [
          {
            ...ingredient,
            selected: 1,
          },
        ],
      };

      dispatch(addUpdateItemAsync({ id: userId, request }))
        .then(() => {
          dispatch(getItemsByIdAsync({ id: userId, country: storedCode }));
          navigate(`/cart`);
        });
    }

  };
  return (
    <div className="px-4 sm:px-8 lg:px-16 mb-8">
      <div className="flex justify-between mb-3 sm:mb-5">
        <span className="text-black lg:text-2xl text-[18px] sm:text-[24px] font-bold px-2">{title}</span>

      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-6">
        {categoriesDetail.map((product: any) => (
          <div
            key={product.id}
            className="flex flex-col rounded-xl border border-[#EDEDED] shadow-none"
            onClick={() => handleView(product.id)}
          >
            <Link to={`/product/${product.id}`} className="flex-shrink-0">
              <div className="bg-[#F3F9FB] p-3 flex items-center justify-center h-[160px] sm:h-[180px]">
                <img
                  src={product.image}
                  alt="Product"
                  className="w-[120px] sm:w-[140px] lg:w-[160px] object-contain"
                />
              </div>
            </Link>
            <div className="border-t border-[#EDEDED]" />
            <div className="bg-white p-3 flex flex-col flex-grow">
              <div className="flex-grow">
                <h3 className="text-[12px] sm:text-[15px] text-[#222222] font-medium mb-1 lg:line-clamp-1">
                  {product.name.charAt(0).toUpperCase() + product.name.slice(1)}
                </h3>
                {/* <p className="text-[12px] sm:text-[14px] text-gray-600 mb-3">
                  {product.description}
                </p> */}
                {product.weight && <p className="text-xs text-[#222222] mx-3 mb-1">{product.weight}</p>}
              </div>
              <div className="border-t border-[#EDEDED] mb-3" />
              <div className="flex items-center justify-between mt-auto">
                <div className="flex items-center">
                  <span className="text-[16px] sm:text-[18px] font-medium text-[#3BB77E]">
                    {storedCode === "IN" ? "₹" : "£"}{product.price}
                  </span>
                  {/* <span className="text-[14px] sm:text-[16px] font-medium text-[#ADADAD] line-through ml-2">
                    {storedCode === "IN" ? "₹" : "£"}{product.originalPrice}
                  </span> */}
                </div>
                <button className="py-1.5 px-4 sm:px-5 border border-[#0B6E27] rounded-md font-medium text-[12px] sm:text-[14px] text-[#0B6E27] hover:bg-[#0B6E27] hover:text-white transition-colors" onClick={(e) => {
                  e.stopPropagation();
                  handleAdd(product.id, product);
                }}>
                  Add
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <LoginModal
          onClose={() => {
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
};

export default ProductTrial;
