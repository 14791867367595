import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ConfirmRefundPopupProps {
  onClose: () => void;
  onConfirm: () => void;
  total: number | null;
}

const ConfirmRefundPopup: React.FC<ConfirmRefundPopupProps> = ({ onClose, onConfirm, total }) => {
  const navigate = useNavigate();
    const storedCode = localStorage.getItem('countryCode');

  const handleConfirm = () => {
    onConfirm(); 
    navigate('/profile');
    window.scrollTo(0, 0); 
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-4">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-full max-w-md relative">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-2xl p-2 font-bold"
          onClick={onClose}
          aria-label="Close"
        >
          &times;
        </button>

        <h2 className="text-lg md:text-xl font-dm-sans mb-4 text-center">Confirm Refund</h2>

        <p className="text-gray-700 font-dm-sans mb-6 text-sm md:text-base text-center">
          You will receive a refund of <span className="text-green-700">{storedCode === "IN" ?"₹" : "£"}{total}</span> within five business days via the same UPI method used for your original payment.
        </p>

        <div className="flex flex-col md:flex-row justify-between space-y-3 md:space-y-0 md:space-x-4">
          <button
            className="border border-gray-300 text-black w-full md:flex-1 px-4 py-2 rounded-lg text-sm md:text-base font-dm-sans font-medium"
            onClick={onClose}
            aria-label="Cancel"
          >
            Cancel
          </button>
          <button
            className="bg-[#C30F16] text-white w-full md:flex-1 px-4 py-2 rounded-lg text-sm md:text-base font-dm-sans font-medium"
            onClick={handleConfirm}
            aria-label="Confirm Refund"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRefundPopup;
