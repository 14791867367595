import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonial from "../../../src/assets/images/testin1.png";
import im2 from "../../../src/assets/images/Nicole.png";
import im3 from "../../../src/assets/images/Media.jpeg";
import im4 from "../../../src/assets/images/Xin (1).jpeg";
import im5 from "../../../src/assets/images/Larry (1).png";
import im6 from "../../../src/assets/images/ron (1).jpeg";
import im7 from "../../../src/assets/images/Ashish (1).png";

const testimonialsByCountry = {
  IN: [
    {
      image: Testimonial,
      name: "Kevin",
      
      title: "Elevating Every Occasion with AnyFeast India",
      text: "Always impressed by the superior quality of their products, from farm-fresh produce to premium Indian ingredients. The authentic spices and regional specialties are amazing.",
    },
    {
      image: im2,
      name: "Nicole",
      
      title: "AnyFeast India: Making Every Meal Memorable",
      text: "I'm a regular customer in Mumbai and I can't get enough of the amazing products and service. The traditional Indian ingredients and regional specialties are always fresh.",
    },
    {
      image: im3,
      name: "Richa",
     
      title: "AnyFeast India Crafted for Every Craving",
      text: "The selection of Indian groceries and spices is incredible! I love how easy it is to find regional specialties from across India.",
    },
  ],
  UK: [
    {
      image: im4,
      name: "Xin",
    
      title: "Where Every Meal Tells a Story - AnyFeast",
      text: "AnyFeast is a lifesaver! I'm a busy mom, and having customisable meal kits delivered right to my door is a game-changer. The recipes are easy to follow, and my kids love the meals. Plus, I can tweak the ingredients to suit our dietary needs. It's worth every penny!",
    },
    {
      image: im5,
      name: "Larry",
     
      title: "Perfecting Meals, One Bite at a Time",
      text: "AnyFeast is amazing! As someone with specific dietary restrictions, being able to tailor my meals exactly how I need them is invaluable. The recipes are fun to make and always turn out great. I'm a customer for life!",
    },
    {
      image: im6,
      name: "Ron",
      
      title: "The Secret Ingredient to Every Celebration",
      text: "AnyFeast has been a lifesaver for our busy household. The meal kits are so convenient, and the customisation options mean everyone gets what they want. The recipes are easy to follow and the food always tastes Authentic.",
    },
    {
      image: im7,
      name: "Ashish",
      
      title: "Your Partner for Perfect Occasions",
      text: "My experience with AnyFeast has been nothing short of amazing. The variety of meals and the ability to customise them is perfect for our family. Every meal we've had so far has been delicious and easy to prepare. Highly recommend!",
    },
  ],
};

const StarRating = () => (
  <div className="flex justify-start mb-2 ">
    {[...Array(5)].map((_, index) => (
      <svg 
        key={index} 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 24 24" 
        fill="currentColor" 
        className="w-6 h-6 text-yellow-400"
      >
        <path 
          fillRule="evenodd" 
          d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.529.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.776-.415-2.212.749-2.305l5.404-.433 2.082-5.007z" 
          clipRule="evenodd" 
        />
      </svg>
    ))}
  </div>
);

const TestimonialCarousel: React.FC = () => {
  const [currentTestimonials, setCurrentTestimonials] = useState(testimonialsByCountry.IN);

  useEffect(() => {
    const handleCountryChange = () => {
      const countryCode = localStorage.getItem('countryCode') || 'IN';
      setCurrentTestimonials(testimonialsByCountry[countryCode as keyof typeof testimonialsByCountry]);
    };

    handleCountryChange();
    window.addEventListener('storage', handleCountryChange);

    return () => {
      window.removeEventListener('storage', handleCountryChange);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerPadding: "120px",
          className: "center",
        },
      },
    ],
  };

  return (
    <div className="container mx-auto p-4 mt-1 md:mt-2 lg:mt-10">
      
      <h2 className="text-xl md:text-3xl font-bold text-center mb-4 mt-2 font-sans text-[#313131]">
        What Our Customers Say About Us
      </h2>
      <div className="slider-container">
        <Slider {...settings}>
          {currentTestimonials.map((testimonial, index) => (
            <div key={index} className="p-4">
              <div className="bg-white rounded-lg shadow-lg border border-[#E6E6E6] flex flex-col md:flex-row items-center md:items-start md:min-h-[300px] min-h-[600px]">
                {/* <div className="md:w-1/3 flex items-center justify-center md:pr-6"> */}
                  <div className="w-full md:w-[350px] h-[300px] flex-shrink-0 overflow-hidden rounded-lg">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="rounded-lg  w-full h-full object-cover"
                    />
                  </div>
                {/* </div> */}
                <div className="md:w-2/3 ml-0 md:ml-6 text-[#525252] p-6 md:p-8 font-sans flex flex-col justify-between relative">
                  {/* <div className="mb-4 md:mb-10"> */}
                  
                  <div className="flex flex-col items-start">
  <h4 className="text-md text-[#525252] font-medium mb-2">
    {testimonial.name}
  </h4>
  <h3 className="text-lg md:text-xl text-[#525252] font-bold w-full">
    {testimonial.title}
  </h3>
  <div className="mt-2">
    <StarRating />
  </div>
</div>
<div>
<p className="mt-5 text-[#525252]">{testimonial.text}</p>
                   
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialCarousel;