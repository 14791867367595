import React from 'react';
import Work from '../../../src/assets/images/work.png';
import Work1 from '../../../src/assets/images/work1.png';
import Work2 from '../../../src/assets/images/work2.png';

interface Step {
  title: string;
  description: string;
  icon: string;
}

const steps: Step[] = [
  { title: 'Choose your Authentic Meal Kit', description: 'Select meals through our app.', icon: Work },
  { title: 'We deliver it to you at Door Step', description: 'Get your meal kit delivered.', icon: Work1 },
  { title: 'Enjoy 3 meals for the Price of 1', description: 'Enjoy your meals with family.', icon: Work2 },
];

const HowItWorksSection: React.FC = () => {
  return (
    <div className="p-4 lg:p-6 bg-white mt-1 md:mt-2 lg:mt-10">
      <h2 className="lg:text-2xl text-lg font-bold text-center mb-6 font-sans">How does it Work</h2>
      <div className="grid grid-cols-3 lg:grid-cols-3 gap-6">
        {steps.map((step) => (
          <div key={step.title} className="text-center">
            <img src={step.icon} alt={step.title} className="mx-auto mb-4 lg:h-[280px] lg:w-[300px]" />
            <h3 className="lg:text-xl text-[10px] font-semibold font-sans">{step.title}</h3>
            <p className="lg:text-sm text-[7px] text-gray-600 font-sans">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorksSection;
