import React from "react";
import blog1 from "../../assets/images/blog1.png";
import blog2 from "../../assets/images/blog2.png";
import blog3 from "../../assets/images/blog3.png";
import blog4 from "../../assets/images/blog4.png";
import author from "../../assets/images/author.png";
import image1 from "../../assets/images/b2.png";
import image2 from "../../assets/images/b3.png";
import image3 from "../../assets/images/b4.png";
import image4 from "../../assets/images/b5.png";
import image6 from "../../assets/images/b6.png";
import image7 from "../../assets/images/b7.png";
import image8 from "../../assets/images/b8.png";
import image5 from "../../assets/images/christmasBlog.png";
import logo from "../../../src/assets/images/logoo.png";
import LikeCommentShare from "./LikeCommentShare";

const Blog: React.FC = () => {
  return (
    <div className="p-4 sm:p-8 lg:p-10">
      <h2 className="text-base sm:text-3xl font-semibold mb-4 mt-15">
        Blog
      </h2>
      <h3 className="text-[22px] lg:text-[46px] text-[#172B4D] lg:font-medium font-semibold sm:mt-10 lg:mr-10">
        5 Reasons to Order an AnyFeast Christmas Box This Year
      </h3>

      <div className="flex items-center space-x-2 pt-5 sm:mt-6 mb-8">
        <img src={logo} alt="Author" className="w-10 h-8" />
        <div className="flex flex-col justify-center">
          <p className="lg:text-[15px] text-xs font-normal text-[#172B4D]">
            By Anyfeast
            <span className="block sm:inline">
              <span className="hidden sm:inline"> | </span>
              Published on December 6, 2024 | 5 min read
            </span>
          </p>
        </div>
      </div>

      <img
        src={image5}
        alt="Main Blog"
        className="w-full lg:h-[550px] h-[200px] mb-6 object-cover"
      />

      <div className="lg:flex lg:space-x-8">
        {/* Left Section */}
        <article className="lg:w-3/4 text-sm w-full font-normal lg:text-[20px] leading-8 lg:pr-14 text-[#313131] space-y-4 lg:space-y-6">
          <p>
            The holiday season is a time for joy, family, and festive feasting.
            But let’s face it—preparing the perfect Christmas meal can be
            daunting. This year, skip the stress and focus on the magic of the
            season with an AnyFeast Christmas Box. Here’s why it’s the ultimate
            choice for your festive celebrations. <p />
            <h5 className="font-bold lg:text-2xl text-xl pt-4 pb-2">
              1. Stress-Free Festive Cooking
            </h5>
            Preparing a Christmas feast often involves hours of shopping,
            prepping, and planning. With an AnyFeast Christmas Box, all the hard
            work is done for you. From curated ingredients to detailed
            instructions, we ensure every dish turns out perfectly with minimal
            effort. You’ll save time while still wowing your guests with
            homemade, gourmet meals. Whether you’re cooking for two or a large
            gathering, this kit has options tailored to your needs.
            <h5 className="font-bold lg:text-2xl text-xl  py-2">
              2. Premium Quality Ingredients
            </h5>
            At AnyFeast, we believe great food starts with great ingredients.
            That’s why our Christmas Box is packed with the freshest produce,
            ethically-sourced meats, and artisanal pantry items. Each ingredient
            is carefully chosen to bring out the best flavors, ensuring your
            Christmas meal is nothing short of spectacular.
            <h5 className="font-bold lg:text-2xl text-xl py-2">
              3. Customizable Menus for Every Palate
            </h5>
            Christmas celebrations are as diverse as the families that celebrate
            them, and our AnyFeast Christmas Box caters to every taste. Whether
            you’re looking for a traditional roast with all the trimmings or a
            vegetarian spread with innovative sides, our boxes are customizable
            to suit your preferences. We even offer options for those with
            dietary restrictions, so everyone at your table feels included.
          </p>
        </article>

        {/* Full-width Section for small to medium screens */}
        <div className="lg:hidden font-normal text-sm leading-8 lg:text-[22px] space-y-4 lg:space-y-6 lg:font-normal lg:my-8">
        <p className="">
          <h5 className="font-bold lg:text-2xl text-xl py-2">
            4. Sustainable and Waste-Free Cooking
          </h5>
          The holidays often lead to food waste, but not with AnyFeast. Each
          Christmas Box is designed with sustainability in mind. Portions are
          pre-measured to minimize waste, and the packaging is eco-friendly. By
          choosing AnyFeast, you’re not just making your holiday easier—you’re
          also contributing to a greener planet.
          <h5 className="font-bold lg:text-2xl text-xl py-2">
            5. Unforgettable Culinary Experience
          </h5>
          Cooking an AnyFeast Christmas Box is more than just preparing a
          meal—it’s an experience. From discovering new recipes to creating
          restaurant-quality dishes in your kitchen, it brings joy back to
          cooking. Plus, our step-by-step guides and pre-measured ingredients
          make even complex dishes approachable. Your Christmas feast will be
          the highlight of the day, filled with flavors your loved ones will
          remember for years to come.
          <h5 className="font-bold lg:text-3xl text-2xl pt-5 pb-2 text-red-600">
            Why Choose AnyFeast This Christmas?
          </h5>
          The AnyFeast Christmas Box is perfect for busy families, novice cooks,
          or anyone who wants a memorable meal without the holiday stress. With
          a focus on quality, customization, and sustainability, we’re
          redefining how you celebrate the holidays.
          <h5 className="font-bold lg:text-2xl text-xl pt-3 pb-2">
            {" "}
            Order Your AnyFeast Christmas Box Today!
          </h5>
          Don’t let the hustle of the holidays keep you from enjoying the
          season. Place your order now and look forward to a stress-free,
          delicious, and joyous Christmas celebration.
        </p>
        </div>

        {/* Right Section */}
        <div className="lg:w-1/4 mt-12 mr-8 lg:mt-0">
          <h4 className="text-md font-normal text-[#42526E] mb-4">
            Popular Posts
          </h4>
          <div className="space-y-6">
            {/* Article 1 */}
            <div className="flex items-center space-x-4">
              <img src={image1} alt="Post 1" className="w-20 h-20 rounded-md" />
              <div className="pr-3">
                <p className="text-[10px] font-extralight text-[#42526E]">
                  PRODUCT
                </p>
                <p className="text-md font-light text-[#172B4D]">
                The Secret to Perfectly Balanced Meals: How AnyFeast Chooses Ingredients and Designs Recipe Kits
                </p>
              </div>
            </div>
            <hr className="border-t-2 border-[#8797B1]" />

            {/* Article 2 */}
            <div className="flex items-center space-x-4">
              <img src={image2} alt="Post 2" className="w-20 h-20 rounded-md" />
              <div>
                <p className="text-[10px] font-extralight text-[#42526E]">
                  PRODUCT | ENTERPRISE
                </p>
                <p className="text-lg text-[#172B4D]">
                Creating Memorable Meals at Home: How AnyFeast Makes Cooking an Experience
                </p>
              </div>
            </div>
            <hr className="border-t-2 border-[#8797B1]" />

            {/* Article 3 */}
            <div className="flex items-center space-x-4">
              <img src={image3} alt="Post 3" className="w-20 h-20 rounded-md" />
              <div>
                <p className="text-[10px] font-extralight text-[#42526E]">
                  PRODUCTIVITY
                </p>
                <p className="text-lg text-[#172B4D]">
                Supporting Local Stores in the Age of Quick Commerce: A Call for Collective Action 
                </p>
              </div>
            </div>
            <hr className="border-t-2 border-[#8797B1]" />

            {/* Article 4 */}
            <div className="flex items-center space-x-4">
              <img src={image4} alt="Post 4" className="w-20 h-20 rounded-md" />
              <div>
                <p className="text-[10px] font-extralight text-[#42526E]">
                  PRODUCTIVITY
                </p>
                <p className="text-lg text-[#172B4D]">
                Top 10 Weeknight Dinners to Simplify Your Busy Week with AnyFeast
                </p>
              </div>
            </div>
            <hr className="border-t-2 border-[#8797B1]" />
          </div>
        </div>
      </div>

      {/* Full-width Section for large screens */}
      <div className="hidden lg:block font-normal leading-8 lg:text-[20px] leading-8 space-y-4 lg:space-y-4 lg:font-normal">
        <p className="">
          <h5 className="font-bold text-2xl py-2">
            4. Sustainable and Waste-Free Cooking
          </h5>
          The holidays often lead to food waste, but not with AnyFeast. Each
          Christmas Box is designed with sustainability in mind. Portions are
          pre-measured to minimize waste, and the packaging is eco-friendly. By
          choosing AnyFeast, you’re not just making your holiday easier—you’re
          also contributing to a greener planet.
          <h5 className="font-bold text-2xl py-2">
            5. Unforgettable Culinary Experience
          </h5>
          Cooking an AnyFeast Christmas Box is more than just preparing a
          meal—it’s an experience. From discovering new recipes to creating
          restaurant-quality dishes in your kitchen, it brings joy back to
          cooking. Plus, our step-by-step guides and pre-measured ingredients
          make even complex dishes approachable. Your Christmas feast will be
          the highlight of the day, filled with flavors your loved ones will
          remember for years to come.
          <h5 className="font-bold text-3xl pt-5 pb-2 text-red-600">
            Why Choose AnyFeast This Christmas?
          </h5>
          The AnyFeast Christmas Box is perfect for busy families, novice cooks,
          or anyone who wants a memorable meal without the holiday stress. With
          a focus on quality, customization, and sustainability, we’re
          redefining how you celebrate the holidays.
          <h5 className="font-bold text-2xl pt-3 pb-2">
            {" "}
            Order Your AnyFeast Christmas Box Today!
          </h5>
          Don’t let the hustle of the holidays keep you from enjoying the
          season. Place your order now and look forward to a stress-free,
          delicious, and joyous Christmas celebration.
        </p>
        <LikeCommentShare />

        {/* <h3 className="text-base sm:text-md lg:text-[28px] text-[#172B4D] font-semibold mt-4">
          Order your Luchi and Cholar Dal recipe kit from AnyFeast today and
          experience the rich history and flavors of Bengali cuisine in your own
          home!
        </h3> */}
      </div>

      <div className="mt-8 px-4 lg:px-0">
        <h4 className="text-lg text-[#42526E] mb-4">
          HERE ARE SOME RELATED ARTICLES YOU MAY FIND INTERESTING:
        </h4>

        <div className="space-y-6">
          {/* Article 1 */}
          <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
            <img
              src={image6}
              alt="Blog 1"
              className="w-full h-[250px] lg:w-36 lg:h-36 object-cover rounded-md"
            />
            <div>
              <p className="text-xs mt-2 mb-2 text-[#42526E]">
                PRODUCT - 4 MINUTE READ
              </p>
              <p className="text-xl mb-2 sm:text-2xl text-[#172B4D]">
              Faces Behind the Feast: Celebrating Local Artisans in a Global Kitchen 
              </p>
              <p className="text-xs sm:text-base text-[#172B4D]">
              In a world dominated by mass production and convenience, it’s easy to forget the origins of our food. Behind every ingredient in your pantry, behind every meal you cook, there is a story—{" "}
              </p>
            </div>
          </div>

          {/* Article 2 */}
          <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
            <img
              src={image7}
              alt="Blog 2"
              className="w-full h-[250px] lg:w-36 lg:h-36 object-cover rounded-md"
            />
            <div>
              <p className="text-xs mt-2 mb-2 text-[#42526E]">
                PRODUCT - 6 MINUTE READ
              </p>
              <p className="text-xl mb-2 sm:text-2xl text-[#172B4D]">
              Ways AnyFeast Can Help You Stick to Your Dietary Goals
              </p>
              <p className="text-xs sm:text-base text-[#172B4D]">
              In today’s busy world, maintaining dietary goals can feel overwhelming. Between tight schedules and endless food options, it’s easy to fall off track. That’s where AnyFeast comes in—{" "}
              </p>
            </div>
          </div>

          {/* Article 3 */}
          <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
            <img
              src={image8}
              alt="Blog 3"
              className="w-full h-[250px] lg:w-36 lg:h-36 object-cover rounded-md"
            />
            <div>
              <p className="text-xs mt-2 mb-2 text-[#42526E]">
                PRODUCTIVITY - 7 MINUTE READ
              </p>
              <p className="text-xl mb-2 sm:text-2xl text-[#172B4D]">
              10 Reasons Why Recipe Kits are Perfect for Culinary Exploration
              </p>
              <p className="text-xs sm:text-base text-[#172B4D]">
              Exploring new cuisines and flavors can be incredibly exciting, but sourcing the right ingredients and mastering new techniques can also be intimidating. Recipe kits bridge the gap...{" "}
              </p>
            </div>
          </div>

          {/* Article 4 */}
          {/* <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
            <img
              src={blog4}
              alt="Blog 4"
              className="w-full lg:w-48 lg:h-48 object-cover rounded-md"
            />
            <div>
              <p className="text-xs mt-2 mb-2 text-[#42526E]">
                PRODUCTIVITY - 7 MINUTE READ
              </p>
              <p className="text-xl mb-2 sm:text-2xl text-[#172B4D]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <p className="text-xs sm:text-base text-[#172B4D]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                eu turpis molestie, dictum est a, mattis tellus. Sed dignissim,
                metus nec fringilla accumsan, risus sem{" "}
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Blog;
