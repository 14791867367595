import { configureStore } from "@reduxjs/toolkit";
import reciepeReducer from "./services/reducers/reciepeReducer";
import servingsReducer from './services/servingsSlice';
import quantitiesReducer from './services/quantitiesSlice';


export const store = configureStore({
    reducer: {
        receipe: reciepeReducer,
        servings: servingsReducer,
        quantities: quantitiesReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;