import React from 'react';

interface StepProgressProps {
    currentStep: number;
}

const StepProgress: React.FC<StepProgressProps> = ({ currentStep }) => {
    const steps = ['CART', 'ADDRESS', 'PAYMENT'];

    return (
        <div className="flex justify-between items-center w-full max-w-md mx-auto">
            {steps.map((step, index) => (
                <div key={index} className="flex items-center">
                    <span
                        className={`text-lg font-bold font-sans ${currentStep > index + 1
                                ? 'text-[#0B6E27]'
                                : currentStep === index + 1
                                    ? 'text-[#0B6E27]'
                                    : 'text-[#AAAAAA]'
                            }`}
                    >
                        {step}
                    </span>

                    {index < steps.length - 1 && (
                        <div
                            className="mx-2"
                            style={{
                                borderTop: '2px dotted #0B6E27',
                                width: '100px', // Fixed width for consistent line length
                                height: '0px',
                            }}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

export default StepProgress;
