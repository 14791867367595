
export type Quantities = { [key: number]: number };

export const handleQuantityChange = (
  index: number,
  action: 'add' | 'increase' | 'decrease',
  quantities: Quantities,
  setQuantities: React.Dispatch<React.SetStateAction<Quantities>>
) => {
  setQuantities((prev) => {
    const currentQuantity = prev[index] || 0;

    if (action === 'add') {
      return {
        ...prev,
        [index]: 1, 
      };
    }

    if (action === 'increase') {
      return {
        ...prev,
        [index]: currentQuantity + 1, 
      };
    }

    if (action === 'decrease') {
      if (currentQuantity === 1) {
      
        const updatedQuantities = { ...prev };
        delete updatedQuantities[index];
        return updatedQuantities;
      } else {
        return {
          ...prev,
          [index]: currentQuantity - 1, 
        };
      }
    }

    return prev;
  });
};

export interface Ingredient {
  id: number;
  name: string;
  description: string;
  quantity: string; // Adjust type based on your needs
  category: string;
  allergy_info: string | null;
  brand: string;
  price: string;
  status: string;
  revised_price: string;
  country: string;
  sub_category: string;
  image: string | null;
  search_term: string;
  cuisine: string;
  rating: string;
  source_url: string | null;
  ingredient_type: number;
}

export const capitalizeWords = (str: string) => {
  return str
    ?.toLowerCase() 
    .split(' ') 
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
    .join(' '); 
}